import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function RadialGradientBackground({ className, colorName, children, ...otherProps }) {
  return (
    <div className={cx(styles.radialBackground, className, styles['_' + colorName])} {...otherProps}>
      <div className={styles.background} />
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

RadialGradientBackground.propTypes = {
  className: PropTypes.string,
  colorName: PropTypes.oneOf(['orange', 'blue']),
};

RadialGradientBackground.defaultProps = {
  colorName: 'orange',
};
