import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import { prepareWidgetsBlock } from 'core/utils/content';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent, SideIndent } from 'core/components/Wrappers';
import TopicContent from 'core/components/TopicContent';

import MajorScreen from 'site/components/MajorScreen';
import * as siteWidgets from 'site/widgets'; // eslint-disable-line import/no-namespace

const relationships = resolveRelationships(['content'], {});
const blocks = prepareWidgetsBlock(siteWidgets);


/**
 * Топик типа wizard.
 * Предполагает прохождение тестов (рамблер квиз).
 * Рендерим только виджет snippet.
 */
function TopicWizard({ content, isMobile }) {
  const {
    headline,
    announce,
  } = content.attributes;

  const {
    content: {
      widgets: contentWidgets,
    },
  } = relationships(content);

  const verticalIndent = isMobile ? '48rem' : '120rem';

  const snippet = contentWidgets.find(widget => widget.type === 'snippet');

  return (
    <>
      <MajorScreen
        negativeMobile
        title={headline}
        description={announce}
      />
      <Indent top={verticalIndent} />
      <SideIndent>
        <TopicContent
          blocks={blocks}
          content={[snippet]}
          disableDefaultNavigation
        />
      </SideIndent>
      <Indent top={verticalIndent} />
    </>
  );
}

TopicWizard.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(TopicWizard);
