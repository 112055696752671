import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'site/components/Button';

import Arrow from 'site/icons/ArrowRight';

import styles from './index.styl';


const ICON_SIZE = '18rem';

export default function SliderArrow(props) {
  const {
    className,
    arrowsShift,
    size,
    position,
    callback,
    type,
  } = props;

  return (
    <div
      onClick={callback}
      className={cx(
        styles.zone,
        styles[`_${position}Arrow`],
        className,
      )}
    >
      <style jsx>{`
        .${styles.zone}
          width ${size}rem

          &.${styles._leftArrow}
            padding-left ${arrowsShift}rem

          &.${styles._rightArrow}
            padding-right ${arrowsShift}rem
      `}</style>
      <Button type={type} round>
        <div className={styles.arrowWrapper}>
          <Arrow
            className={styles.icon}
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        </div>
      </Button>
    </div>
  );
}

SliderArrow.defaultProps = {
  size: 54,
  arrowsShift: 0,
};

SliderArrow.propTypes = {
  className: PropTypes.string,
  arrowsShift: PropTypes.number,
  size: PropTypes.number,
  position: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'tertiary']),
  callback: PropTypes.func,
};
