/* eslint-disable import/prefer-default-export */
import { POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG } from 'site/constants';

import WantToBuy from 'site/icons/WantToBuy.svg';
import HappyToHave from 'site/icons/HappyToHave.svg';
import WantToSale from 'site/icons/WantToSale.svg';

export const PILES_DATA = [
  {
    slug: POKUPKA_SLUG,
    buttonText: 'Хочу купить',
    icon: WantToBuy,
  },
  {
    slug: VLADENIE_SLUG,
    buttonText: 'Есть авто',
    icon: HappyToHave,
  },
  {
    slug: PRODAGA_SLUG,
    buttonText: 'Хочу продать',
    icon: WantToSale,
  },
];
