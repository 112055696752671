import PropTypes from 'prop-types';

import SmartImage from 'core/components/Image';

import GradientMask from 'site/components/GradientMask';

import CaptionCredits from './CaptionCredits';

import styles from './index.styl';

export default function Image({ block, parentProps }) {
  const {
    versions,
    alt,
    caption,
  } = block.attributes;

  const {
    attributes: {
      headline,
    },
  } = parentProps.topic.content;

  /**
   * Свойство block.position устанавливается на уровне LB.
   * Если несколько виджетов текст идут последовательно (друг за другом),
   * то block.position не соответствует позиции виджета в ответе от API:
   * block.position !== parentProps.content[block.position]
   *
   * Определяем позицию виджета по ID.
   */
  const position = parentProps.content.findIndex(({ id }) => id === block.id);
  const nextNotMarkdown = parentProps.content[position + 1]?.type !== 'markdown';

  const { isMobile } = parentProps;

  const titleText = caption || headline;
  const altText = alt || titleText;
  const imageMaxWidth = isMobile ? 700 : 1920;

  return (
    <div className={styles.image}>
      <style jsx>{`
        .${styles.shadow}
          max-width ${imageMaxWidth}rem
      `}</style>
      <div className={styles.shadow}>
        <GradientMask>
          <SmartImage
            versions={versions}
            maxWidth={imageMaxWidth}
            alt={altText}
            title={titleText}
            aspectRatio={1.6}
            useSchema
          />
        </GradientMask>
        <CaptionCredits fitToBottom={nextNotMarkdown} block={block} />
      </div>
    </div>
  );
}

Image.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
};
