import PropTypes from 'prop-types';

import styles from './index.styl';


export default function PVPLables({ labels }) {
  if (labels?.length === 0) return null;

  return (
    <div className={styles.labels}>
      {labels.map((label, i) => (
        <div key={i} className={styles.label}>
          {label}
        </div>
      ))}
    </div>
  );
}

PVPLables.propTypes = {
  /** Контент карточек. */
  labels: PropTypes.array,
};
