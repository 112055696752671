import PropTypes from 'prop-types';
import color from 'core/libs/color';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import { POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG } from 'site/constants';

import styles from './index.styl';

const colorModifier = (initialColor, opacityDegree) => color(initialColor).rgb().alpha(opacityDegree).string();

function PaperBackground(props) {
  const {
    children,
    className,
    gradientSlug,
    colorName,
    opacityDegree,
    theme: {
      colors: {
        white,
        [colorName]: defaultColor,
      },
    },
  } = props;

  return (
    <div
      className={cx(styles.paperBackground, className, gradientSlug && styles['_' + gradientSlug])}
      style={{
        '--defaultColor': colorModifier(defaultColor, opacityDegree),
        '--white': colorModifier(white, opacityDegree),
        '--blue800': colorModifier('#209FCC', opacityDegree),
        '--green950': colorModifier('#0DA8AB', opacityDegree),
        '--yellow100': colorModifier('#FFDD11', opacityDegree),
      }}
    >
      {children}
    </div>
  );
}

PaperBackground.propTypes = {
  /** Кастомный класс компонента. */
  className: PropTypes.string,

  /** PVP-слаг для градиента. */
  gradientSlug: PropTypes.oneOf([VLADENIE_SLUG, POKUPKA_SLUG, PRODAGA_SLUG]),

  /** Цвет сплошной заливки. */
  colorName: PropTypes.oneOf(['aqua200', 'black']),

  /** Прозрачность цвета. */
  opacityDegree: PropTypes.number,

  /** @ignore */
  theme: PropTypes.shape({
    colors: PropTypes.object,
  }),
};

PaperBackground.defaultProps = {
  colorName: 'aqua200',
  opacityDegree: 0.7,
};

const PaperBackgroundWithHOCs = withTheme(PaperBackground);
PaperBackgroundWithHOCs.displayName = 'PaperBackground';

export default PaperBackgroundWithHOCs;

export { PaperBackground as StorybookComponent };
