import PropTypes from 'prop-types';
import cx from 'classnames';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withMenu from 'core/components/withMenu';
import withTheme from 'core/components/theme';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';

import Logo from 'site/icons/Logo.svg';
import Search from 'site/icons/Search.svg';
import Button from 'site/components/Button';

import { PILES_DATA } from 'site/components/PVPButtons/constants';

import styles from './index.styl';

const linkLogo = 'https://sberauto.com';

function ShapkaMenu(props) {
  const {
    theme,
    menu,
    handleSearchSubmit,
    inputRef,
    isFixedShapka,
    isMobile,
  } = props;

  const {
    black,
    blue700,
    gray800,
    gray100,
  } = theme.colors;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .menuLink
          color ${black}
          text-decoration none
          &:visited
            color ${black}
          &:hover
            color ${blue700}

        .${styles.menuTitle}
          font 400 20rem/20rem ${theme.fonts.nuance}
          color ${gray800}

        .${styles.dynamicMenu}
          :global(.mobile) &
            border-left 2rem solid ${gray100}
      `}</style>
    </scope>
  );

  const menuItems = menu?.data?.attributes?.menu_items || [];
  const slicedMenuItems = menuItems.slice(0, 9); // остальное в верстку не влезет

  const widthLogo = isMobile ? '132rem' : '150rem';
  const heightLogo = isMobile ? '22rem' : '24rem';

  const menuList = (
    <ul className={scoped.wrapClassNames(styles.menu, styles.dynamicMenu)}>
      {slicedMenuItems.map((item, i) => (
        <li className={styles.list} key={i}>
          <Link className={scoped.wrapClassNames('menuLink')} to={item.link}>
            {item.content}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className={cx(styles.shapkaMenu, isFixedShapka && styles.fixedShapka)}>
      <style jsx>{`
        .${styles.shapkaMenu}
          color ${black}

        .${styles.searchInput}
          font 400 24rem/34rem ${theme.fonts.text}
          color ${black}
          border 1px solid ${black}
          &::placeholder
            color ${black}

          :global(.mobile) &
            font 400 16rem/16rem ${theme.fonts.text}

        .${styles.searchContent}
          :global(.desktop) &
            border-left 2px solid ${gray100}

      `}</style>
      <div className={styles.menuContent}>
        <div className={styles.menuWrapper}>
          <Texts type='buttonCTA' className={styles.textsList}>
            <ul className={scoped.wrapClassNames(styles.menu, styles.staticMenu)}>
              {PILES_DATA.map(item => {
                const {
                  buttonText,
                  slug,
                } = item;
                return (
                  <li className={styles.list} key={slug}>
                    <Link className={scoped.wrapClassNames('menuLink')} to={'/' + slug}>
                      {buttonText}
                    </Link>
                  </li>
                );
              })}
            </ul>
            <Mobile>
              <Link to={linkLogo} className={styles.logo}>
                <Logo width={widthLogo} height={heightLogo} />
              </Link>
            </Mobile>
          </Texts>
          <Texts type={isMobile ? 'buttonMenu' : 'buttonCTA'}>
            <div className={styles.content}>
              {menuList}
            </div>
          </Texts>
        </div>
        <div className={styles.searchContent}>
          <form
            className={styles.searchForm}
            onSubmit={handleSearchSubmit}
          >
            <input
              ref={inputRef}
              type='text'
              className={styles.searchInput}
              placeholder='Поиск'
              name='query'
            />
            <Button
              size='extraSmall'
              className={styles.searchButton}
              onSubmit={handleSearchSubmit}
              background='#0083EB'
              height={isMobile ? '48rem' : '56rem'}
              minWidth={isMobile ? '48rem' : '56rem'}
              typeAttribute='submit'
            >
              <Search
                className={styles.searchIcon}
                height={isMobile ? '16rem' : '24rem'}
                width={isMobile ? '16rem' : '24rem'}
              />
            </Button>
          </form>
        </div>
      </div>
      <scoped.styles />
    </div>
  );
}

ShapkaMenu.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  menu: PropTypes.object,
  handleSearchSubmit: PropTypes.func,
  inputRef: PropTypes.object,
  isMobile: PropTypes.bool,
  isFixedShapka: PropTypes.bool,
};

const ShapkaMenuWithHOCs = withMenu('main')(withTheme(withBreakpoint(ShapkaMenu)));
ShapkaMenuWithHOCs.displayName = 'ShapkaMenu';

export default ShapkaMenuWithHOCs;

export { ShapkaMenu as StorybookComponent };
