import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Arrow from './Arrow';


const POSITIONS = ['left', 'right'];

export default function SliderArrows(props) {
  const {
    prevHandler,
    nextHandler,
    ...arrowProps
  } = props;

  const positionsMap = {
    hide: {
      left: false,
      right: false,
    },
    callback: {
      left: prevHandler,
      right: nextHandler,
    },
  };

  return POSITIONS.map((position, i) => positionsMap.hide[position] ? null : (
    <Fragment key={`${position}_${i}`}>
      <Arrow
        position={position}
        callback={positionsMap.callback[position]}
        {...arrowProps}
      />
    </Fragment>
  ));
}

SliderArrows.defaultProps = {
  size: 54,
  arrowsShift: 0,
  prevHandler: () => null,
  nextHandler: () => null,
};

SliderArrows.propTypes = {
  /** Колбек левой стрелки. */
  prevHandler: PropTypes.func.isRequired,

  /** Колбек правой стрелки. */
  nextHandler: PropTypes.func.isRequired,

  /** Смещение стрелок от края. */
  arrowsShift: PropTypes.number,

  /** Размер кнопки. */
  size: PropTypes.number,
};

SliderArrows.displayName = 'SliderArrows';

export { SliderArrows as StorybookComponent };
