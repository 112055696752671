import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import Skoda from './logos/skoda.svg';
import SkodaMobile from './logos/skoda_mobile.svg';
import Renault from './logos/renault.svg';
import RenaultMobile from './logos/renault_mobile.svg';
import Hyundai from './logos/hyundai.svg';
import HyundaiMobile from './logos/hyundai_mobile.svg';
import BMW from './logos/bmw.svg';
import BMWMobile from './logos/bmw_mobile.svg';
import Kia from './logos/kia.svg';
import KiaMobile from './logos/kia_mobile.svg';
import Nissan from './logos/nissan.svg';
import NissanMobile from './logos/nissan_mobile.svg';
import Ford from './logos/ford.svg';
import FordMobile from './logos/ford_mobile.svg';
import Lada from './logos/lada.svg';
import LadaMobile from './logos/lada_mobile.svg';
import Toyota from './logos/toyota.svg';
import ToyotaMobile from './logos/toyota_mobile.svg';
import VW from './logos/vw.svg';
import VWMobile from './logos/vw_mobile.svg';

export const rootUrl = '/news/';

export const brands = [
  {
    slug: 'skoda',
    icon: {
      [DESKTOP]: Skoda,
      [MOBILE]: SkodaMobile,
    },
  },
  {
    slug: 'renault',
    icon: {
      [DESKTOP]: Renault,
      [MOBILE]: RenaultMobile,
    },
  },
  {
    slug: 'hyundai',
    icon: {
      [DESKTOP]: Hyundai,
      [MOBILE]: HyundaiMobile,
    },
  },
  {
    slug: 'bmw',
    icon: {
      [DESKTOP]: BMW,
      [MOBILE]: BMWMobile,
    },
  },
  {
    slug: 'kia',
    icon: {
      [DESKTOP]: Kia,
      [MOBILE]: KiaMobile,
    },
  },
  {
    slug: 'nissan',
    icon: {
      [DESKTOP]: Nissan,
      [MOBILE]: NissanMobile,
    },
  },
  {
    slug: 'ford',
    icon: {
      [DESKTOP]: Ford,
      [MOBILE]: FordMobile,
    },
  },
  {
    slug: 'lada',
    icon: {
      [DESKTOP]: Lada,
      [MOBILE]: LadaMobile,
    },
  },
  {
    slug: 'toyota',
    icon: {
      [DESKTOP]: Toyota,
      [MOBILE]: ToyotaMobile,

    },
  },
  {
    slug: 'volkswagen',
    icon: {
      [DESKTOP]: VW,
      [MOBILE]: VWMobile,
    },
  },
];
