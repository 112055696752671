import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import {
  authorFetcher,
} from 'core/fetchers/authorPage';
import { dateFormat } from 'core/utils/dates';
import { denormalizeData } from 'core/utils/api';
import pluralize from 'core/utils/pluralize';
import { getFullYearsDelta } from 'site/utils';

import withPageHocs from 'core/components/withPageHocs';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Indent } from 'core/components/Wrappers';
import { Mobile, Desktop } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import Texts from 'core/components/Texts';
import ThemeSwitch from 'core/components/ThemeSwitch';

import Paginator from 'site/components/Paginator';
import Section from 'site/components/Section';
import News from 'site/components/News';
import SectionNews from 'site/components/SectionNews';
import BlueRectangleWrapper from 'site/components/BlueRectangleWrapper';

import AuthorBackground from './AuthorBackground';
import InfoTable from './InfoTable';

import styles from './index.styl';


const LIMIT = 15;

function Author(props) {
  const {
    isMobile,
    author,
    rawData,
    match: {
      params: {
        level_2: authorSlug,
      },
    },
  } = props;

  const {
    attributes: {
      name,
      car,
      job_title: jobTitle,
      place_of_birth: placeOfBirth,
      experience,
      birthday,
      biography,
      career,
      background,
    },
  } = author;

  const birthDate = birthday ? new Date(birthday) : null;

  const lastDate = new Date();

  const age = birthDate
    ? getFullYearsDelta(birthDate, lastDate)
    : null;

  const ageText = age
    ? age + ' ' + pluralize(age, ['год', 'года', 'лет'])
    : '';

  const experienceText = experience
    ? experience + ' ' + pluralize(experience, ['год', 'года', 'лет'])
    : '';

  const infoData = [
    {
      label: 'Карьера',
      value: career,
    },
    birthDate !== null && birthDate !== undefined && {
      label: 'Дата рождения',
      value: `${dateFormat(birthDate, 'dd MMMM y')} ${ageText && `(${ageText})`}`,
    },
    {
      label: 'Должность',
      value: jobTitle,
    },
    {
      label: 'Место рождения',
      value: placeOfBirth,
    },
    experience >= 1 && {
      label: isMobile ? 'Стаж' : 'Стаж вождения',
      value: experience && `${experienceText}`,
    },
    {
      label: 'Авто',
      value: car,
    },
    !isMobile && biography !== null && biography !== undefined && {
      label: 'От автора',
      value: <Texts type='quote' className={styles.value}>{biography}</Texts>,
    },
  ];

  const filteredInfoData = infoData?.filter(item => item?.value && item.value !== 0);

  const indent = isMobile ? '32rem' : '80rem';

  return (
    <Page
      title={name}
      description={career}
    >
      <div className={styles.header}>
        <AuthorBackground
          title={name}
          image={background}
        />
        <ThemeSwitch name='dark'>
          <div className={styles.content}>
            <Desktop>
              {filteredInfoData.length > 0  &&
                <BlueRectangleWrapper>
                  <div className={styles.info}>
                    <InfoTable tableData={filteredInfoData} />
                  </div>
                </BlueRectangleWrapper>
              }
            </Desktop>
            <Mobile>
              {biography &&
                <BlueRectangleWrapper>
                  <Texts type='quote' className={styles.value}>{biography}</Texts>
                  <Texts type='body'>
                    От автора
                  </Texts>
                </BlueRectangleWrapper>
              }
            </Mobile>
          </div>
        </ThemeSwitch>
      </div>
      <Mobile><Indent bottom='16rem' /></Mobile>
      <Mobile>
        <Section
          className={styles.sectionTable}
          withBackground
          withPaddingHorizontal
        >
          {filteredInfoData.length > 0  && <div className={styles.infoMobile}>
            <InfoTable tableData={filteredInfoData} />
          </div>}
        </Section>
      </Mobile>
      <Indent bottom={indent} />
      <SectionNews
        columnReverse
        content={{
          topics: rawData.topics.slice(0, 9),
        }}
        sectionTitle='Публикации автора'
        showFullWidthArticle={false}
        promoMobileIndex={5}
      />
      <Indent bottom={indent} />
      {rawData.topics.length > 9 &&
        <Section withBackground>
          <div className={styles.news}>
            <News content={rawData.topics.slice(9)} />
          </div>
        </Section>
      }
      <Indent bottom={isMobile ? '24rem' : '80rem'}  />
      <Paginator
        apiParams={{ author: authorSlug }}
        startOffset={LIMIT}
        filteredCount={rawData.count}
      />
      <Indent bottom={isMobile ? '64rem' : '80rem'} />
    </Page>
  );
}

Author.propTypes = {
  rawData: PropTypes.object,
  author: PropTypes.object,
  isMobile: PropTypes.bool,
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    })
  ),
  match: PropTypes.shape({
    params: PropTypes.shape({
      level_2: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const dataProvider = resolve({
  author: authorFetcher(),
  rawData: (props) => {
    const {
      bebopApi,
      news,
      consoleError,
      match: {
        params: {
          level_2: authorSlug,
        },
      },
    } = props;

    return news || bebopApi
      .getTopics({
        limit: LIMIT,
        include: 'image,authors',
        fields: 'headline,alternative_headline,list_headline,link,published_at',
        with_filtered_count: 1,
        author: authorSlug,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('rawData', { topics: [], count: 0 }));
  },
});

export default withPageHocs(dataProvider)(withBreakpoint(Author));
