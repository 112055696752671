/**
 * Палитра цветов используемых темами сайта.
 *
 * Цели:
 * - показать все используемые цвета в одном файле;
 * - избежать определения одного и того же цвета в разных темах;
 * - удобно разносить палитру по темам.
 *
 * Правила описания оттенков одного цвета: gray100, gray200, gray300 etc.
 *
 * Если возникнут сложности с именованием цвета, можно воспользоваться генератором имен.
 * https://icolorpalette.com/color/FDB913
 */
const palette = {
  white: '#fff',
  black: '#080808',

  gray100: '#E9F3FF',
  gray300: '#D9D9D9',
  gray500: '#C8CEE5',
  gray800: '#7F8192',

  red100: '#FC6252',

  orange100: '#FF8227',
  orange200: '#F55D05',

  yellow100: '#FBF101',
  yellow200: '#FFDD11',
  yellow300: '#FFD83D',

  aqua200: '#0DA8AB',

  green100: '#D2FFCA',
  green200: '#ABFFBA',
  green300: '#00FF56',
  green350: '#2EE850',
  green400: '#34C48E',
  green500: '#3EAD61',
  green600: '#24B23E',
  green700: '#21A038',

  blue100: '#64B6FC',
  blue300: '#8FB5FF',
  blue500: '#0083EB',
  blue700: '#3F81FD',
  blue900: '#095ABA',

  ghost200: 'rgba(255, 255, 255, 0.2)',

  boxShadowActive: '0 4px 8px 0 rgba(1, 8, 21, 0.3) inset',
};

export default palette;
