import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';

import ScrollerVertical from 'site/components/ScrollerVertical';

import { getVisitedPVP } from 'site/utils/cookie';

import TrafficLight, { trafficColors } from 'site/icons/TrafficLight';

import { scrollToElement } from '../utils';
import Skeleton from '../Skeleton';

import styles from './index.styl';


function ChecklistDesktop({
  content,
  title,
  loading,
  theme: {
    texts: {
      body: bodyAtoms,
    },
    colors: {
      black,
      blue500,
    },
    fonts: {
      display,
    },
  },
}) {
  const [visitedTopics, setVisitedTopics] = useState([]);

  useEffect(() => {
    setVisitedTopics(getVisitedPVP());
  }, []);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.content}
          border-top 2rem solid #F55D05
        .${styles.title}
          border-bottom 2rem solid #F55D05
        .${styles.button}
          font ${bodyAtoms.font}
          letter-spacing ${bodyAtoms.letterSpacing}
          font-family ${display}
          color ${black}
          &:hover
            color ${blue500}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.checklist}>
      <Texts
        className={scope.wrapClassNames(styles.title)}
        type='accent'
      >
        {title}
      </Texts>
      <ScrollerVertical className={scope.wrapClassNames(styles.content)}>
        {loading
          ? <Skeleton />
          : content.map((topic, index) => {
            const {
              id,
              attributes: {
                headline,
                list_headline: listHeadline,
              },
            } = topic;
            const isVisited = visitedTopics.includes(id);
            const { colorInner, colorOuter } = trafficColors[isVisited ? 2 : 1];

            return (
              <button
                className={scope.wrapClassNames(styles.button)}
                key={index}
                onClick={() => scrollToElement(topic.id)}
              >
                <TrafficLight
                  width={37}
                  height={37}
                  colorInner={colorInner}
                  colorOuter={colorOuter}
                  className={styles.icon}
                />
                {listHeadline || headline}
              </button>
            );
          })
        }
      </ScrollerVertical>
      <scope.styles />
    </div>
  );
}

ChecklistDesktop.propTypes = {
  content: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(ChecklistDesktop);
