import PropTypes from 'prop-types';

import coreSnippet from 'core/components/TopicContent/blocks/snippet';

import GradientBackground from 'site/components/GradientBackground';

import { WIZARD_SLUG } from 'site/constants';

import styles from './index.styl';

export default function Snippet({ block, parentProps, ctx }) {
  const { provider } = block.attributes;
  const { topic_type: topicType } = parentProps.topic.content.attributes;

  if (topicType === WIZARD_SLUG) {
    return <div className={styles.snippetWizard}>{coreSnippet(block, parentProps, ctx)}</div>;
  }

  const isYoutube = provider === 'youtube';

  if (isYoutube) {
    const { isMobile, theme } = parentProps;
    const { topicContent: topicContentAtoms } = theme.controls;
    const { top, bottom } = topicContentAtoms.widget;

    const topIndent = Math.abs(top) * (isMobile ? 2 : 1);
    const bottomIndent = Math.abs(bottom) * (isMobile ? 2 : 1);

    return (
      <div className={styles.youtube}>
        <style jsx>{`
          .${styles.youtube}
            background ${theme.colors.black}
            padding ${topIndent}px 0 ${bottomIndent}px
        `}</style>
        {coreSnippet(block, parentProps, ctx)}
      </div>
    );
  }

  return (
    <GradientBackground className={styles.gradient}>
      <div className={styles.snippet}>
        {coreSnippet(block, parentProps, ctx)}
      </div>
    </GradientBackground>
  );
}

Snippet.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
