import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';


export default function GradientBackground({ className, children, ...otherProps }) {
  return (
    <div className={cx(styles.gradientBackground, className)} {...otherProps}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

GradientBackground.propTypes = {
  className: PropTypes.string,
};
