import PropTypes from 'prop-types';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import modelPropTypes, { rubricAttributes } from 'core/utils/prop-types/model';

import resolve from 'core/resolver/resolve';

import withPageHocs from 'core/components/withPageHocs';
import bindPropsHOC from 'core/components/bindProps';

import Page from 'core/components/Page';
import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import RubricHeader, { PVPLabels } from 'site/components/RubricHeader';

import CardPvp1 from 'site/cards/CardPvp1';

import { PVP_SLUG } from 'site/constants';

import PVPContent from './PVPContent';
import contentMap from './contentMap';

import styles from './index.styl';


/**
 * Блок ПВП (покупка / владение / продажа) как следует из технической документации.
 */
function PVPPage({ content, rubricContent, loading, isMobile, match }) {
  const { rubricSlug } = match.params;
  const { checklistTitle, title, labels } = contentMap[rubricSlug] || {};

  const {
    meta_title: metaTitle,
    meta_description: metaDescription,
  } = rubricContent?.data?.attributes || {};

  return (
    <Page
      title={metaTitle}
      description={metaDescription}
    >
      <Indent bottom={isMobile ? 0 : '80rem'}>
        <RubricHeader
          headlineSize='smaller'
          className={styles.header}
          title={title}
          footerContent={<PVPLabels labels={labels} />}
        />
      </Indent>
      <PVPContent
        content={content}
        title={checklistTitle}
        loading={loading}
      />
      <Indent bottom={isMobile ? '48rem' : '80rem'} />
    </Page>
  );
}

const dataProvider = resolve({
  content: props => {
    const {
      consoleError,
      bebopApi,
      match: { params: { rubricSlug } },
      content,
    } = props;

    return content || bebopApi
      .getTopics({
        topic_type: PVP_SLUG,
        rubric_root: rubricSlug,
        include: filterRequiredParams([CardPvp1], 'include'),
        fields: filterRequiredParams([CardPvp1], 'fields'),
      })
      .then(denormalizeData)
      .catch(consoleError('content on pvp page', []));
  },

  rubricContent: (props) => {
    const {
      bebopApi,
      consoleError,
      match: { params: { rubricSlug } },
      rubric,
    } = props;

    return rubric || bebopApi
      .getRubric({
        rubric_slug: rubricSlug,
      })
      .catch(consoleError('pvp rubric content', {}));
  },
});

PVPPage.propTypes = {
  content: PropTypes.array,
  rubricContent: PropTypes.shape({
    data: modelPropTypes(rubricAttributes),
  }),
  loading: PropTypes.bool,
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      rubricSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
};


const EnhancedPVPPage = withBreakpoint(PVPPage);

const Loader = bindPropsHOC({ loading: true })(EnhancedPVPPage);

export default withPageHocs(dataProvider, Loader)(withBreakpoint(EnhancedPVPPage));
