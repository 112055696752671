import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import themePropTypes from 'core/utils/prop-types/theme';

function SolidBackground({ theme, colorName, children, className }) {
  const colorFromTheme = theme.colors[colorName];

  if (!colorFromTheme) return children;

  return (
    <div className={cx('solidBackground', className)}>
      <style jsx>{`
        .solidBackground
          background-color ${colorFromTheme}
      `}</style>
      {children}
    </div>
  );
}


SolidBackground.propTypes = {
  className: PropTypes.string,
  colorName: PropTypes.oneOf(['aqua200']),
  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      aqua200,
    }
  }`),
};

SolidBackground.defaultProps = {
  colorName: 'aqua200',
};

const SolidBackgroundWithHOCs = withTheme(SolidBackground);
SolidBackgroundWithHOCs.displayName = 'SolidBackground';

export default SolidBackgroundWithHOCs;

export { SolidBackground as StorybookComponent };
