import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import ChecklistDesktop from './Desktop';
import ChecklistMobile from './Mobile';


function Checklist({ isMobile, ...otherProps }) {
  if (!otherProps.content?.length) return null;

  const ChecklistBreakpoint = isMobile ? ChecklistMobile : ChecklistDesktop;

  return <ChecklistBreakpoint {...otherProps} />;
}

Checklist.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Checklist);
