import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import { Indent } from 'core/components/Wrappers';

import Checklist from './Checklist';
import CardsFeed from './CardsFeed';

import styles from './index.styl';


function PVPContent({ content, loading, title, isMobile }) {
  if (!content?.length) return null;

  return (
    <Section>
      <Block desktop={4} mobile={12}>
        <Indent className={styles.checklistWrapper} right={isMobile ? 0 : '48rem'}>
          <Checklist
            title={title}
            content={content}
            loading={loading}
          />
        </Indent>
      </Block>
      <Block desktop={8} mobile={12}>
        <CardsFeed
          content={content}
          isMobile={isMobile}
          loading={loading}
        />
      </Block>
    </Section>
  );
}

PVPContent.propTypes = {
  content: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.string,
  slug: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PVPContent);
