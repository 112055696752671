export default `
## Вступили в силу 24 сентября 2024 года.
<br />
Настоящие правила рассылок (Правила) определяют общие условия рассылки Сообщений Пользователям, осуществляемой Провайдером и/или Партнерами.


1. ТЕРМИНЫ

1.1	Провайдер – юридическое лицо, предоставляющее Пользователям Ресурс. Наименование Провайдера размещено на Ресурсе, на котором Пользователь совершает Подписку на рассылку.

1.2	Ресурс – сайт, проект, сервис и/или иной продукт в сети Интернет, предоставляемый Провайдером.

1.3	Пользователь – физическое лицо, использующее Ресурс и обладающее необходимой дееспособностью для принятия Правил.

1.4	Сообщения – информационные сообщения, в том числе новостного и/или рекламного характера, связанные с Ресурсом и/или тематикой Ресурса, а также с товарами/услугами Провайдера и/или Партнеров.

1.5	Подписка на рассылку – действия Пользователя при использовании Ресурса, направленные на систематическое получение Сообщений от Провайдера и/или Партнеров.

1.6	Партнеры – третьи лица, выступающие в качестве поставщиков товаров/услуг, информация о которых доступна посредством Ресурсов

2. ДЕЙСТВИЕ ПРАВИЛ

2.1	Правила являются публичной офертой в соответствии со ст. 437 Гражданского кодекса РФ. Подписка на рассылку означает полный и безоговорочный акцепт Правил Пользователем.

2.2	В случае несогласия Пользователя с каким-либо из положений Правил, Пользователь обязан воздержаться от Подписки на рассылку.

2.3	Правила являются неотъемлемой частью Условий использования Ресурса.

2.4	Правила и отношения Пользователя и Провайдера и/или Партнеров, возникающие в связи с Подпиской на рассылку, регулируются законодательством РФ.

2.5	Правила могут быть изменены Провайдером в одностороннем внесудебном порядке. Анонсирование изменений и публикация измененных Правил производятся на Ресурсе. Используя Ресурс и/или продолжая получать Сообщения после изменения Правил, Пользователь принимает Правила полностью и безоговорочно в измененном виде.

3. УСЛОВИЯ РАССЫЛКИ СООБЩЕНИЙ

3.1	Принимая Правила, Пользователь выражает свое согласие на получение Сообщений от Провайдера и/или Партнеров по адресу электронной почты, указанному Пользователем при Подписке на рассылку.

3.1.1	В случае, если при использовании Ресурса Пользователь указывает информацию о номере его мобильного телефона, Пользователь выражает свое согласие на получение SMS-Сообщений от Провайдера и/или Партнеров по указанному номеру.

3.2	Если при осуществлении Подписки на рассылку Пользователь предоставляет персональные данные, то принимая условия Правил, Пользователь дает согласие Провайдеру и Партнерам на обработку (включая сбор, запись, систематизацию, накопление, хранение, уточнение, извлечение, использование, предоставление, доступ, обезличивание, блокирование, удаление и уничтожение) предоставленных персональных данных в целях получения Сообщений и продвижения Ресурсов, продуктов (услуг) Провайдера и Партнеров. Согласие действует с момента предоставления данных Пользователем до достижения целей их обработки и может быть отозвано путем направления Провайдеру письменного заявления.

3.3	Провайдер исходит из того, что Подписка на рассылку осуществляется непосредственно Пользователем, а также, что адрес электронной почты и, если применимо, номер мобильного телефона, указанные Пользователем при Подписке на рассылку, являются корректными и принадлежат Пользователю. Ответственность за правомерность предоставления сведений при Подписке на рассылку и их достоверность несет исключительно Пользователь.

3.4	Пользователь вправе в любое время отказаться от получения Сообщений путем перехода по соответствующей гиперссылке, содержащейся в Сообщении.
`;
