import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';
import loader from 'core/resolver/loader';

import { Mobile, Desktop } from 'core/components/breakpoint';

import { POKUPKA_SLUG, PRODAGA_SLUG, VLADENIE_SLUG } from 'site/constants';


import DesktopMenu from './Desktop';
import MobileMenu from './Mobile';
import Skeleton from './Skeleton';

const PVP_SLUGS = [POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG];

function PvpMenu({ menus }) {
  return (
    <>
      <Mobile><MobileMenu /></Mobile>
      <Desktop><DesktopMenu menus={menus} /></Desktop>
    </>
  );
}

PvpMenu.propTypes = {
  menus: PropTypes.object,
};

const dataProvider = resolve('menus', ({ bebopApi, loading, menus }) => {
  if (loading) return {};
  if (menus) return menus;

  return bebopApi
    .getMenus()
    .then(({ data }) => data)
    .then(data => {
      /**
       * Правильно запрашивать нужные menu_slug на уровне API.
       * Но за неимением функционала, выбираем pvp menu самостоятельно.
       */
      return PVP_SLUGS.reduce((acc, slug) => {
        const menuData = data.find(menu => menu.attributes.slug === slug);
        return { ...acc, [slug]: menuData };
      }, {});
    })
    .catch(() => console.error('pvp menu request failed'));
});

export default loader(Skeleton)(dataProvider(PvpMenu));
