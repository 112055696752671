import PropTypes from 'prop-types';
import cx from 'classnames';

import themeSwitcher from 'core/components/themeSwitcher';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

function CaptionCredits({ block, fitToBottom }) {
  const {
    url: captionUrl,
    caption,
    credits,
    credits_url: creditsUrl,
  } = block.attributes;

  const Credits = (
    <div className={styles.credits}>
      <span className={styles.copy}>&copy;&nbsp;</span>
      <MarkdownWrapper inline>{credits}</MarkdownWrapper>
      {caption && '/'}
    </div>
  );

  const Caption = (
    <MarkdownWrapper inline>{caption}</MarkdownWrapper>
  );

  return (
    <Texts type='bodySmall' className={cx(styles.captionCredits, fitToBottom && styles._fitToBottom)}>
      {creditsUrl
        ? <Link type='primary' to={creditsUrl}>{Credits}</Link>
        : <>{Credits}</>
      }
      <div className={styles.caption}>
        {captionUrl
          ? <Link type='primary' to={captionUrl}>{Caption}</Link>
          : <>{Caption}</>
        }
      </div>
    </Texts>
  );
}

CaptionCredits.propTypes = {
  block: PropTypes.object,
  fitToBottom: PropTypes.bool,
};

export default themeSwitcher('dark')(CaptionCredits);
