import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import themeSwitcher from 'core/components/themeSwitcher';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import GradientMask from 'site/components/GradientMask';

import { cardDateFormat } from 'site/utils';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const IMAGE_MAX_WIDTH = 845;

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
  authors: [],
});

function CardRect2(props) {
  const {
    content,
    imageRatio,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
    authors,
  } = relationships(content) || {};

  const authorNames = authors.map(author => author.attributes.name).join(', ');
  const authorNamesPrefix = (authors.length > 1 ? 'Авторы' : 'Автор');

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardRect2}
          max-width ${IMAGE_MAX_WIDTH}px
          aspect-ratio ${imageRatio}
      `}</style>
    </scope>
  );

  const title = listHeadline || headline;


  return (
    <Link
      to={link}
      title={title}
      type='primary'
      className={scope.wrapClassNames(
        styles.cardRect2,
      )}
    >
      {versions.original && (
        <div className={scope.wrapClassNames(styles.cover)}>
          <GradientMask hideEllipsis>
            <SmartImage
              versions={versions}
              maxWidth={IMAGE_MAX_WIDTH}
              aspectRatio={imageRatio}
            />
          </GradientMask>
        </div>
      )}
      <div className={scope.wrapClassNames(styles.body)}>
        <Texts type='accent' className={styles.title}>
          <MarkdownWrapper children={title} inline />
        </Texts>
        <Texts type='bodySmall' className={styles.info}>
          {authorNames && <span>{authorNamesPrefix + ': ' + authorNames}</span>}
          {publishedAt && <span>{cardDateFormat(publishedAt)}</span>}
        </Texts>
      </div>
      <scope.styles />
    </Link>
  );
}

CardRect2.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /** @ignore */
  theme: PropTypes.object,
};

CardRect2.defaultProps = {
  imageRatio: 1.74,
};


const Card = themeSwitcher('dark')(withTheme(CardRect2));

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CardRect2 as StorybookComponent };
export default Card;
