import { Fragment } from 'react';
import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';

import { withBreakpoint } from 'core/components/breakpoint';

import Texts from 'core/components/Texts';
import { NegativeMobile, Indent } from 'core/components/Wrappers';
import Popular from 'core/components/Popular';
import SmartImage from 'core/components/SmartImage';

import SectionHeader from 'site/components/SectionHeader';
import Section from 'site/components/Section';
import MobileDrum from 'site/components/MobileDrum';
import News from 'site/components/News';
import Button from 'site/components/Button';

import image from './images/404.png';
import imageMobile from './images/404-mobile.png';

import styles from './index.styl';


function NotFound({ isMobile }) {
  return (
    <Fragment>
      <NegativeMobile>
        <div className={styles.errorHeader}>
          <SmartImage
            className={styles.image}
            url={isMobile ? imageMobile : image}
            maxWidth={isMobile ? 360 : 1664}
            aspectRatio={isMobile ? 1.21 : 2.43}
            placement='contain'
          />
        </div>
      </NegativeMobile>
      <Indent top={isMobile ? '32rem' : '80rem'} />
      <Texts type={isMobile ? 'title4' : 'accent'} className={styles.errorText}>
        Упс! Эта страница, похоже, уехала{isMobile ? ' ' : <br />}на незапланированный пит-стоп. Пока мы её ищем,{isMobile ? ' ' : <br />}можете вернуться на главную страницу или перейти{isMobile ? ' ' : <br />}к другим разделам сайта!
      </Texts>
      <Button
        link='/'
        minWidth='100%'
        className={styles.button}
        size='large'
      >
        Перейти на главную
      </Button>
      <Indent top={isMobile ? '56rem' : '96rem'} />
      <Section className={styles.section} withBackground={!isMobile}>
        <div className={styles.popularTitle}>
          <SectionHeader {...isMobile && { titleType: 'accent' }}>
            Вам может быть <mark>интересно</mark>
          </SectionHeader>
        </div>
        <Indent top={isMobile ? '24rem' : '64rem'} />
        <div className={styles.popular}>
          <Popular
            renderer={isMobile ? MobileDrum : News}
            limit={4}
            include='image'
            fields='headline,list_headline,published_at,link'
          />
        </div>
      </Section>
      <Indent top={isMobile ? '48rem' : '61rem'} />
    </Fragment>
  );
}

NotFound.propTypes = {
  isMobile: PropTypes.bool,
};

export default skip(withBreakpoint(NotFound));
