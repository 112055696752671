import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';
import Texts from 'core/components/Texts';
import ThemeSwitch from 'core/components/ThemeSwitch';

import Control from './Control';
import Modal from './Modal';
import ListOfContents, { LineOfContents }  from './List';


const relationships = resolveRelationships(['content'], {}, {});

function TableOfContent({ content, isMobile, controlClassName }) {
  const { is_has_contents: isHasContents } = content.attributes;
  const { widgets } = relationships(content).content;

  const [opened, toggleOpened] = useState(false);
  const handleModal = useCallback(() => {
    toggleOpened(!opened);
  }, [opened]);

  if (!isHasContents) return null;

  const contentItems = [];
  widgets.map((block, i) => {
    const {
      type,
      attributes: {
        body,
      },
    } = block;

    if (type === 'markdown' && isHasContents && /#{2}/.test(body)) {
      contentItems.push(
        <LineOfContents
          key={i}
          body={body}
          {...isMobile && { handleModalMobile: handleModal }}
        />
      );
    }
  }).filter(Boolean);

  return (
    <>
      <style jsx>{`
        :global(html)
          scroll-behavior smooth
      `}</style>
      <Desktop>
        <Texts type='title4'>Оглавление</Texts>
        <Indent bottom='36rem' />
        <ListOfContents>
          {contentItems}
        </ListOfContents>
      </Desktop>
      <Mobile>
        <ThemeSwitch name='dark'>
          <Control
            controlClassName={controlClassName}
            handleModal={handleModal}
          />
          <Modal opened={opened} handleModal={handleModal}>
            <ListOfContents>
              {contentItems}
            </ListOfContents>
          </Modal>
        </ThemeSwitch>
      </Mobile>
    </>
  );
}

TableOfContent.propTypes = {
  content: PropTypes.object,
  /** @ognore */
  isMobile: PropTypes.bool,
  controlClassName: PropTypes.string,
};

const TableOfContentWithHOCs = withBreakpoint(TableOfContent);
TableOfContentWithHOCs.displayName = 'TableOfContent';

export { TableOfContent as StorybookComponent };
export default TableOfContentWithHOCs;
