import cx from 'classnames';

import styles from './index.styl';


export default function ChecklistSkeleton() {
  return new Array(4).fill(null).map((v, i) => (
    <div key={i} className={cx(styles.buttonSkeleton, 'lb-skeleton')} />
  ));
}
