import Layout, { Header, Content, Footer } from 'core/components/Layout';

import Shapka from 'site/components/Shapka';
import Scooter from 'site/components/Scooter';

export default function SiteLayout({ children }) {
  return (
    <Layout>
      <Header>
        <Shapka />
      </Header>
      <Content>
        {children}
      </Content>
      <Footer>
        <Scooter />
      </Footer>
    </Layout>
  );
}
