import PropTypes from 'prop-types';
import { Fragment } from 'react';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';

import Texts from 'core/components/Texts';

import styles from './index.styl';


function TextBlock({ title, text, isOrderText, isOfficeText, isMobile }) {
  const WrapperComponent = isMobile ? Fragment : 'div';

  return (
    <WrapperComponent>
      <Texts
        type='title2'
        className={styles.headline}
        tag='h2'
      >
        {title}
      </Texts>
      <Texts type='body' className={cx(styles.text, isOrderText && styles._orderText, isOfficeText && styles._isOfficeText)}>
        {text}
      </Texts>
    </WrapperComponent>
  );
}

TextBlock.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  isOrderText: PropTypes.bool,
  isOfficeText: PropTypes.bool,
};

export default withBreakpoint(TextBlock);
