import PropTypes from 'prop-types';

import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';

import SectionHeader from 'site/components/SectionHeader';
import Section from 'site/components/Section';

import Answer from './Answer';

import styles from './index.styl';

export const ANSWER_LIMIT = 5;


function UrgentAnswers({ isMobile, urgentAnswers }) {
  const ScrollerOrEmpty = isMobile ? Scroller : EmptyWrapper;

  return (
    <Section className={styles.section}>
      <SectionHeader>Нужные ответы<Mobile><br /></Mobile> на <mark>срочные</mark> вопросы</SectionHeader>
      <div className={styles.answers}>
        <ScrollerOrEmpty className='js-questions-scroller'>
          <div className={styles.answersGrid}>
            {urgentAnswers.map((answer, index) => {
              return (
                <Answer
                  key={answer.id || index}
                  answer={answer}
                  isMobile={isMobile}
                />
              );
            })}
          </div>
        </ScrollerOrEmpty>
      </div>
    </Section>
  );
}

UrgentAnswers.propTypes = {
  isMobile: PropTypes.bool,
  urgentAnswers: PropTypes.array,
};

UrgentAnswers.defaultProps = {
  urgentAnswers: Array(ANSWER_LIMIT).fill({
    attributes: {
      headline: '',
      link: '',
    },
  }),
};

export default withBreakpoint(UrgentAnswers);
