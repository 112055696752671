import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'core/libs/router';
import skip from 'core/resolver/skip';

import { withBreakpoint } from 'core/components/breakpoint';

import EmptyWrapper from 'core/components/EmptyWrapper';
import Link from 'core/components/Link';
import Button from 'core/components/Button';

import { POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG } from 'site/constants';
import { PILES_DATA } from './constants';

import styles from './index.styl';


function PVPButtons(props) {
  const {
    isMobile,
    tabsMode,
    location,
    buttonType,
    buttonSize,
    handleActiveSlug,
    className,
  } = props;

  /**
   * PVPButtons не всегда используются внутри BaseRoutes (например Scooter).
   * Поэтому мы не всегда можем положиться на match.params.
   * Определяем rubricSlug вручную, по location.pathname.
   */
  const { pathname } = location;
  let rubricSlug;
  if ([POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG].includes(pathname.slice(1))) {
    rubricSlug = pathname.slice(1);
  }

  const [activeSlug, updateActiveSlug] = useState(null);

  useEffect(() => {
    if (tabsMode || rubricSlug === activeSlug) return;
    updateActiveSlug(rubricSlug);
  }, [location, tabsMode, rubricSlug, activeSlug]);

  useEffect(() => {
    if (!tabsMode) return;
    updateActiveSlug(PILES_DATA[0].slug);
  }, [tabsMode]);

  const sortedPiles = rubricSlug && PILES_DATA[0].slug === activeSlug
    ? PILES_DATA
    : [...PILES_DATA].sort(a => a.slug === activeSlug ? -1 : 1);

  return (
    <div className={cx(styles.buttons, className)}>
      {sortedPiles.map((renderData) => {
        const {
          buttonText,
          slug,
        } = renderData;

        const isActive = activeSlug === slug;
        const showLink = !tabsMode || (tabsMode && isActive);

        const LinkWrapper = showLink ? Link : EmptyWrapper;
        const linkProps = showLink && { to: '/' + slug, type: 'primary' };
        const commonButtonProps = {
          disabled: tabsMode ? !showLink && isActive : isActive,
          onClick: () => {
            updateActiveSlug(slug);
            if (!showLink && handleActiveSlug) handleActiveSlug(slug);
          },
        };
        const tabsButtonProps = !showLink && {
          role: 'tab',
          ['aria-selected']: isActive,
        };
        let btnSize =  isMobile ? 'small' : 'medium';
        btnSize = buttonSize ? buttonSize : btnSize;

        if (isMobile) {
          const breakButtonText = buttonText.replace(' ', '\n');
          const text = (breakButtonText || buttonText).replace('!', '');

          return (
            <LinkWrapper
              key={slug}
              className={styles.link}
              {...linkProps}
            >
              <Button
                size={btnSize}
                type={buttonType}
                className={cx(styles.button, buttonType && styles['_' + buttonType])}
                {...commonButtonProps}
                {...tabsButtonProps}
              >
                <span>{text}</span>
              </Button>
            </LinkWrapper>
          );
        }

        return (
          <LinkWrapper
            key={slug}
            {...linkProps}
          >
            <Button
              size={btnSize}
              type={buttonType}
              className={cx(styles.button, buttonType && styles['_' + buttonType])}
              {...commonButtonProps}
              {...tabsButtonProps}
            >
              <span>{buttonText}</span>
            </Button>
          </LinkWrapper>
        );
      })}
    </div>
  );
}

PVPButtons.propTypes = {
  /**
   * По умолчанию все кнопки являются ссылками.
   *
   * Можно перевести кнопки в смешанный режим (табы и ссылки):
   * - активный - ссылка
   * - не активный - кнопка-таб
   */
  tabsMode: PropTypes.bool,

  /**
   * Обработчик для отслеживания выбранного таба
   */
  handleActiveSlug: PropTypes.func,

  /**
   * Тип большой кнопки (в мобильной версии есть еще круглые кнопки)
   */
  buttonType: PropTypes.oneOf([
    'secondary',
  ]),

  /**
   * Размер кнопки
   */
  buttonSize: PropTypes.oneOf([
    'extraSmall',
    'small',
    'medium',
  ]),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,

  /** @ignore */
  className: PropTypes.string,
};

PVPButtons.defaultProps = {
  buttonType: 'secondary',
  handleActiveSlug: () => null,
};

export { PVPButtons as StorybookComponent };

export default withBreakpoint(withRouter(skip(PVPButtons)));
