import PropTypes from 'prop-types';

import { Indent } from 'core/components/Wrappers';
import GridFeed from 'core/components/GridFeed';

import Adfox from 'site/components/Ads';

import CardPvp1 from 'site/cards/CardPvp1';
import CardPvp2 from 'site/cards/CardPvp2';

import Skeleton from './Skeleton';


export default function CardsFeed({ content, loading, isMobile }) {
  const indent = isMobile ? '16rem' : '80rem';
  return loading ? <Skeleton /> : (
    <>
      <GridFeed
        card={isMobile ? CardPvp2 : CardPvp1}
        content={content}
        gap={indent}
        gridTemplateColumns='1fr'
      />
      <Indent top={indent}>
        <Adfox />
      </Indent>
    </>
  );
}

CardsFeed.propTypes = {
  content: PropTypes.array,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
};
