import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';


export default function Section({ className, children, withBackground, withPaddingHorizontal, id }) {
  return (
    <section
      className={cx(
        styles.section, className,
        withBackground && styles._withBackground,
        withPaddingHorizontal && styles._withPaddingHorizontal
      )}
      id={id}
    >
      {children}
    </section>
  );
}

Section.propTypes = {
  className: PropTypes.string,
  /**
   * Задает белый фон секции
   */
  withBackground: PropTypes.bool,
  /**
   * Добавялет боковые отступы для мобильной версии
   */
  withPaddingHorizontal: PropTypes.bool,
  /**
   * Добавляет id к секции
   */
  id: PropTypes.bool,
};
