import PropTypes from 'prop-types';
import { DESKTOP } from 'core/components/breakpoint/values';
import bindPropsHOC from 'core/components/bindProps';
import Link from 'core/components/Link';
import { withBreakpoint } from 'core/components/breakpoint';
import Button from 'site/components/Button';
import { brands, rootUrl } from './constants';
import styles from './index.styl';

const ButtonRound = bindPropsHOC({ type: 'secondary', size: 'extraLarge', className: styles.btn })(Button);

function Brands({ breakpoint, isMobile }) {
  const RenderComponent = isMobile ? ButtonRound : Link;

  return (
    <div className={styles.brands}>
      {brands.map(({
        slug,
        icon,
      }) => {
        const Icon = icon[breakpoint] || icon[DESKTOP];
        const link = rootUrl + slug;
        return (
          <RenderComponent
            key={slug}
            {...isMobile ? { link } : { to: link }}
          >
            <Icon />
          </RenderComponent>
        );
      })}
    </div>
  );
}

Brands.propTypes = {
  isMobile: PropTypes.bool,
  breakpoint: PropTypes.string,
};

export default withBreakpoint(Brands);
