import PropTypes from 'prop-types';
import cx from 'classnames';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import styles from './index.styl';

function Ogryzok(props) {
  const {
    children,
    theme,
    width,
    height,
    isMobile,
    content,
    padding,
    className,
    position,
  } = props;

  const defaultRaduis = isMobile ? '12px' : '40rem';
  const radius = props.radius ?? defaultRaduis;

  const background = props.background || theme.colors.layout;
  const encodedBackground = background.replace('#', '%23');

  return (
    <>
      <style jsx>{`
        .${styles.content}
          &:before
          &:after
            background-image url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 39.953V40h40V0h-.047C38.94 21.605 21.604 38.94 0 39.953Z' fill='${encodedBackground}'/%3E%3C/svg%3E")
      `}</style>

      <div className={cx(styles.ogryzok, styles[position], className)} style={{
        '--bgColor': background,
        '--radius': radius,
        '--width': width,
        '--height': height,
        '--padding': padding,
      }}
      >
        {children}
        <div className={styles.content}>
          {content}
        </div>
      </div>
    </>
  );
}

Ogryzok.propTypes = {
  /**
   * Положение огрызка. Один из 4х углов
   */
  position: PropTypes.oneOf(['bottom-right', 'bottom-left', 'top-right', 'top-left']),
  /**
   * Цвет заливки огрызка. По-умолчанию берется layout из темы
   */
  background: PropTypes.string,
  /**
   * Радиус скруглений. По-умолчанию 40rem на десктопе и 12px на мобилке
   */
  radius: PropTypes.string,
  /**
   * Ширина огрызка
   */
  width: PropTypes.string,
  /**
   * Высота огрызка
   */
  height: PropTypes.string,
  /**
   * Внутренние отступы огрызка
   */
  padding: PropTypes.string,
  /**
   * Содержимое огрызка
   */
  content: PropTypes.node,
  /**
   * Класс, который задается всему контейнеру, содержащему огрызок
   */
  className: PropTypes.string,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

Ogryzok.defaultProps = {
  position: 'bottom-right',
};

export { Ogryzok as StorybookComponent };

export default withBreakpoint(withTheme(Ogryzok));
