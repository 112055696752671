import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';

import Texts from 'core/components/Texts';

import MapPointer from 'site/icons/MapPointer.svg';

import baseStylesDesktop from '../Desktop/index.styl';
import baseStylesMobile from '../Mobile/index.styl';
import localStyles from './index.styl';

import { PILES_DATA } from '../constants';


function Skeleton({ isDesktop }) {
  if (isDesktop) {
    return (
      <div className={baseStylesDesktop.pvpMenuDesktop}>
        {PILES_DATA.map(renderData => {
          const {
            buttonText,
            slug,
          } = renderData;

          return (
            <div className={baseStylesDesktop.pile} key={slug}>
              <div className={cx(baseStylesDesktop.button, localStyles.button)}>
                {buttonText}
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={baseStylesMobile.pvpMenuMobile}>
      {PILES_DATA.map(renderData => {
        const {
          buttonText,
          slug,
        } = renderData;

        return (
          <div className={baseStylesMobile.link} key={slug}>
            <MapPointer />
            <Texts type='bodySmall'>{buttonText}</Texts>
          </div>
        );
      })}
    </div>
  );
}

Skeleton.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(Skeleton);
