import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import H3 from 'core/components/H3';

import CarReview from 'site/components/CarReview';
import MainSwipe from 'site/components/MainSwipe';
import Button from 'site/components/Button';

import CarPrice from '../CarPrice';

import styles from './index.styl';

const requiredPayloadImports = [
  'car_review_meta',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  car_review_meta: {
    price_from: '',
    price_to: '',
  },
});


export default function MobileReview(props)  {
  const {
    topics,
    activeIndex,
    onActiveChange,
  } = props;

  const content = topics[activeIndex];

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes || {};

  const {
    car_review_meta: carReviewMeta,
  } = relationships(content) || {};

  return (
    <div className={styles.content}>
      <div className={styles.headline}>
        <H3>{listHeadline || headline}</H3>
      </div>
      <div className={styles.price}>
        <CarPrice carReviewMeta={carReviewMeta} />
      </div>
      <MainSwipe
        content={topics}
        altHeadlineSlug='alternative_headline'
        activeIndex={activeIndex}
        onActiveIndexChange={onActiveChange}
        version='car_avatar'
        containerType='topicCoverMobile'
      />
      <CarReview
        key={activeIndex}
        carReviewMeta={carReviewMeta}
        targetId={content.id}
        className={styles.carReview}
      />
      <Button
        className={styles.button}
        link={link}
        minWidth='100%'
        size='large'
      >
        Читать обзор
      </Button>
    </div>
  );
}

MobileReview.propTypes = {
  topics: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
};
