import PropTypes from 'prop-types';

import { filterRequiredParams } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';
import GridFeed from 'core/components/GridFeed';

import CardRect1 from 'site/cards/CardRect1';
import { CardVerticalTiny } from 'site/cards/CardVertical';

const CardRect1Desktop = props => (
  <CardRect1
    imageMaxWidth={382}
    aspectRatio={1.37}
    hideAltHeadline
    {...props}
  />
);

const CardVerticalMobile = props => (
  <CardVerticalTiny
    imageMaxWidth={158}
    imageRatio={1.64}
    {...props}
  />
);


function News({ content, isMobile }) {
  if (!content.length) return null;

  return (
    <GridFeed
      card={isMobile ? CardVerticalMobile : CardRect1Desktop}
      content={content}
      gap={isMobile ? '24rem 12rem' : '16rem'}
      gridTemplateColumns={isMobile ? '1fr 1fr' : 'repeat(4, 1fr)'}
      gridTemplateRows={isMobile ? '1fr 1fr' : '440rem'}
    />
  );
}

News.propTypes = {
  /** Контент. */
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

News.displayName = 'News';

const NewsWithHOC = withBreakpoint(News);

NewsWithHOC.requiredPayloadFields = filterRequiredParams([CardRect1, CardVerticalTiny], 'fields');
NewsWithHOC.requiredPayloadImports = filterRequiredParams([CardRect1, CardVerticalTiny], 'include');

export { News as StorybookComponent };

export default NewsWithHOC;
