import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default class Bullets extends PureComponent {
  static propTypes = {
    /**
     * Индекс активного булета.
     */
    activeIndex: PropTypes.number,

    /**
     * Общее число буллетов.
     */
    totalCount: PropTypes.number,
  };

  static defaultProps = {
    activeIndex: 0,
    totalCount: 0,
  };

  render() {
    const {
      totalCount,
      activeIndex,
    } = this.props;

    return (
      <ul className={styles.bullets}>
        {[...Array(totalCount)].map((item, index) => {
          const active = activeIndex === index;
          return (
            <li className={styles.bulletWrapper} key={index}>
              <div className={cx(styles.bullet, active && styles._active)} />
            </li>
          );
        })}
      </ul>
    );
  }
}
