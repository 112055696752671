import PropTypes from 'prop-types';

import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Texts from 'core/components/Texts';

import styles from './index.styl';


export default function MajorTitle({ title, description }) {
  return (
    <>
      <Texts
        tag='h1'
        type='title1'
        className={styles.title}
      >
        {title}
      </Texts>
      <MarkdownWrapper className={styles.description}>
        {description}
      </MarkdownWrapper>
    </>
  );
}

MajorTitle.propTypes = {
  title: PropTypes.node,
  description: PropTypes.string,
};
