import SliderArrow from 'site/components/SliderArrows/Arrow';

import styles from './index.styl';


export function GalleryArrowRight() {
  return (
    <SliderArrow
      type='tertiary'
      className={styles.arrow}
      position='right'
    />
  );
}

export function GalleryArrowLeft() {
  return (
    <SliderArrow
      type='tertiary'
      className={styles.arrow}
      position='left'
    />
  );
}
