import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Scroller from 'core/components/Scroller';

import { getVisitedPVP } from 'site/utils/cookie';

import TrafficLight, { trafficColors } from 'site/icons/TrafficLight';

import { scrollToElement } from '../utils';
import Skeleton from '../Skeleton';

import styles from './index.styl';


export default function ChecklistMobile({
  content,
  loading,
}) {
  const [visitedTopics, setVisitedTopics] = useState([]);

  useEffect(() => {
    setVisitedTopics(getVisitedPVP());
  }, []);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.scroller}
          padding 24rem 16rem
          margin 16rem -16rem
        .${styles.scrollerList}
          vertical-align middle
      `}</style>
    </scope>
  );

  return (
    <Scroller
      className={scoped.wrapClassNames(styles.scroller)}
      listClassName={scoped.wrapClassNames(styles.scrollerList)}
    >
      {loading
        ? <Skeleton />
        : content.map((topic, i) => {
          const {
            id,
            attributes: {
              headline,
              list_headline: listHeadline,
            },
          } = topic;
          const isVisited = visitedTopics.includes(id);
          const { colorInner, colorOuter } = trafficColors[isVisited ? 2 : 1];

          return (
            <button
              className={styles.button}
              key={i}
              onClick={() => scrollToElement(topic.id)}
            >
              <TrafficLight
                width={16}
                height={16}
                colorInner={colorInner}
                colorOuter={colorOuter}
                className={styles.icon}
              />
              <div className={styles.link}>{listHeadline || headline}</div>
            </button>
          );
        })
      }
      <scoped.styles />
    </Scroller>
  );
}

ChecklistMobile.propTypes = {
  content: PropTypes.array,
  loading: PropTypes.bool,
};
