import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';

import ReadDuration from 'site/components/ReadDuration';
import Authors from 'site/components/Authors';
import PublishedDate from 'site/components/PublishedDate';

import styles from './index.styl';


const relationships = resolveRelationships(['authors'], {});

function Info({ content, theme }) {
  const {
    published_at: publishedAt,
    read_duration: readDuration,
  } = content.attributes || {};

  const {
    authors,
  } = relationships(content) || {};

  return (
    <div className={styles.info}>
      <style jsx>{`
        .${styles.info}
          font-family ${theme.fonts.display}
      `}</style>
      <PublishedDate publishedAt={publishedAt} />
      <ReadDuration readDuration={readDuration} />
      <Authors
        authors={authors}
        showOnlyFirst
        showPrefix
        disableLink
      />
    </div>
  );
}

Info.propTypes = {
  content: PropTypes.object,
  theme: PropTypes.object,
};
export default withTheme(Info);
