import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import SliderArrows from 'site/components/SliderArrows';

import styles from './index.styl';


function BlockNavigation(props) {
  const {
    theme,
    topics,
    activeIndex,
    prevHandler,
    nextHandler,
    // goToHandler,
  } = props;

  return (
    <div className={styles.pagination}>
      <style jsx>{`
        .${styles.line}
          background-color ${theme.colors.blue500}

        .${styles.circle}
          background ${theme.colors.blue500}
          &.${styles._active}
            background ${theme.colors.gray100}
            border 6px solid ${theme.colors.blue500}
      `}</style>
      <SliderArrows
        prevHandler={prevHandler}
        nextHandler={nextHandler}
        arrowsShift={44}
      />
      <div className={styles.line}>
        {topics.map((topic, index) => {
          return (
            <div
              key={topic.id}
              // TODO Убираем навигацию по пагинации, потому что c loop
              // свайпер работает не так как ожидается с индексами.
              // Времени разбираться нет
              // onClick={() => goToHandler(index)}
              className={cx(styles.circle, activeIndex === index && styles._active)}
            />
          );
        })}
      </div>
    </div>
  );
}

BlockNavigation.propTypes = {
  theme: PropTypes.object,
  topics: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  prevHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
  // goToHandler: PropTypes.func.isRequired,
};

export default withTheme(BlockNavigation);
