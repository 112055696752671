/**
 * Функция, получающая все отмеченные прочитанными топики на PVP
 */
export const getVisitedPVP = () => {
  if (!process.env.BROWSER_RUNTIME) return [];

  return JSON.parse(localStorage.getItem('pvp-visited-topics')) || [];
};

/**
 * Функция, добавляющая в прочитанные топик на PVP
 */
export const setVisitedPVP = id => {
  if (!process.env.BROWSER_RUNTIME) return;

  const storageName = 'pvp-visited-topics';
  const visitedTopics = JSON.parse(localStorage.getItem(storageName)) || [];

  const newValue = visitedTopics.concat([id]);
  localStorage.setItem(storageName, JSON.stringify(newValue));
};
