import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Texts from 'core/components/Texts';

import MainSwipe from 'site/components/MainSwipe';
import Button from 'site/components/Button';
import SberScore from 'site/components/SberScore';

import styles from './index.styl';


const relationships = resolveRelationships(['test_drive_meta'], null, {
  test_drive_meta: {},
});

export default function MobileTestDrives({ content, activeIndex, onActiveChange }) {
  if (!content.length) return null;

  const topic = content[activeIndex] || {};

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = topic.attributes || {};

  const {
    test_drive_meta: testDriveMeta,
  } = relationships(topic) || {};

  const blockHeadline = listHeadline || headline;

  return (
    <>
      <div className={styles.header}>
        <span className={styles.number}>№{testDriveMeta.number}</span>
        <SberScore
          className={styles.score}
          sberScore={testDriveMeta.sber_score}
        />
      </div>
      <Texts type='title3' className={styles.headline}>{blockHeadline}</Texts>
      <MainSwipe
        content={content}
        altHeadlineSlug='announce'
        activeIndex={activeIndex}
        onActiveIndexChange={onActiveChange}
        gradientClassName={styles.gradientMask}
        hideEllipsis
      />
      <Button
        className={styles.button}
        link={link}
        minWidth='100%'
        size='large'
      >
        Читать обзор
      </Button>
    </>
  );
}

MobileTestDrives.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
};
