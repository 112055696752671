import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import SmartImage from 'core/components/SmartImage';

import GradientMask from 'site/components/GradientMask';

import themePropTypes from 'core/utils/prop-types/theme';
import { imagePropTypes } from 'core/utils/prop-types/relationships';

import styles from './index.styl';


function TopicCover({ image, theme, headline, isMobile }) {
  const {
    versions,
    caption,
    alt,
  } = image;

  if (!versions.original) return null;

  const aspectRatio = isMobile ? 1.11 : 2;

  return (
    <GradientMask className={styles.topicCover} containerType={isMobile ? 'topicCoverMobile' : 'topicCoverDesktop'}>
      <SmartImage
        versions={versions}
        maxWidth={parseInt(theme.layout.maxWidth, 10)}
        aspectRatio={aspectRatio}
        smart
        alt={alt || headline}
        title={caption || headline}
        fetchPriority='high'
        instant
      />
    </GradientMask>
  );
}

TopicCover.propTypes = {
  isMobile: PropTypes.bool,
  image: imagePropTypes(),
  /** @ignore */
  theme: themePropTypes(`{
    layout: {
      maxWidth,
    }
  }`),
  /** Заголовок топика */
  headline: PropTypes.string,
};

TopicCover.contextTypes = {
  webdavHost: PropTypes.string,
  thumborHost: PropTypes.string,
};

const PaperCoverWithHOCs = withTheme(withBreakpoint(TopicCover));
PaperCoverWithHOCs.displayName = 'TopicCover';

export default PaperCoverWithHOCs;

export { TopicCover as StorybookComponent };
