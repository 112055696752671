import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import ArrowLeft from 'site/icons/ArrowInCircleLeft';
import ArrowRight from 'site/icons/ArrowInCircleRight';

import styles from './index.styl';


function Arrows({ prevHandler, nextHandler, theme }) {
  return (
    <div className={styles.arrows}>
      <style jsx>{`
        .${styles.arrow}
          color ${theme.colors.blue500}
          &:active
            color ${theme.colors.blue700}
      `}</style>
      <div
        className={styles.arrow}
        onClick={prevHandler}
      >
        <ArrowRight className={styles.icon} />
      </div>
      <div
        className={styles.arrow}
        onClick={nextHandler}
      >
        <ArrowLeft className={styles.icon} />
      </div>
    </div>
  );
}

Arrows.propTypes = {
  prevHandler: PropTypes.func,
  nextHandler: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(Arrows);
