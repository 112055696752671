import oneLine from 'common-tags/lib/oneLine';

import SBSansTextRegular from './SBSansText/SBSansText-Regular.woff';
import SBSansTextRegularwoff2 from './SBSansText/SBSansText-Regular.woff2';
import SBSansTextItalic from './SBSansText/SBSansText-Italic.woff';
import SBSansTextItalicwoff2 from './SBSansText/SBSansText-Italic.woff2';
import SBSansTextBoldItalic from './SBSansText/SBSansText-BoldItalic.woff';
import SBSansTextBoldItalicwoff2 from './SBSansText/SBSansText-BoldItalic.woff2';

import SBSansDisplay400 from './SBSansDisplay/SBSansDisplay-Regular.woff';
import SBSansDisplay400woff2 from './SBSansDisplay/SBSansDisplay-Regular.woff2';
import SBSansDisplay600 from './SBSansDisplay/SBSansDisplay-Semibold.woff';
import SBSansDisplay600woff2 from './SBSansDisplay/SBSansDisplay-Semibold.woff2';
import SBSansDisplay700 from './SBSansDisplay/SBSansDisplay-Bold.woff';
import SBSansDisplay700woff2 from './SBSansDisplay/SBSansDisplay-Bold.woff2';

const fonts = {
  text: 'SBSansText, sans-serif',
  display: 'SBSansDisplay, sans-serif',
  nuance: 'SBSansText, sans-serif',
  faces: [
    {
      fontFamily: 'SBSansText',
      fontWeight: 400,
      fontStyle: 'normal',
      src: oneLine`
        local('SBSansText'),
        url(${SBSansTextRegular}) format('woff'),
        url(${SBSansTextRegularwoff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'SBSansText',
      fontWeight: 400,
      fontStyle: 'italic',
      src: oneLine`
        local('SBSansText'),
        url(${SBSansTextItalic}) format('woff'),
        url(${SBSansTextItalicwoff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'SBSansText',
      fontWeight: 700,
      fontStyle: 'italic',
      src: oneLine`
        local('SBSansText'),
        url(${SBSansTextBoldItalic}) format('woff'),
        url(${SBSansTextBoldItalicwoff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'SBSansDisplay',
      fontWeight: 400,
      fontStyle: 'normal',
      src: oneLine`
        local('SBSansDisplay'),
        url(${SBSansDisplay400}) format('woff'),
        url(${SBSansDisplay400woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'SBSansDisplay',
      fontWeight: 600,
      fontStyle: 'normal',
      src: oneLine`
        local('SBSansDisplay'),
        url(${SBSansDisplay600}) format('woff'),
        url(${SBSansDisplay600woff2}) format('woff2')
      `,
    },
    {
      fontFamily: 'SBSansDisplay',
      fontWeight: 700,
      fontStyle: 'normal',
      src: oneLine`
        local('SBSansDisplay'),
        url(${SBSansDisplay700}) format('woff'),
        url(${SBSansDisplay700woff2}) format('woff2')
      `,
    },
  ],
};

export default fonts;
