import { Component } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';

import MobileTravels from './Mobile';
import DesktopTravels from './Desktop';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'travel_meta',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

class Travels extends Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    content: PropTypes.object,
  };

  state = {
    activeIndex: 0,
  };

  changeActiveIndex = ({ realIndex }) => {
    this.setState({ activeIndex: realIndex });
  };

  render() {
    const { isMobile, content } = this.props;

    if (!content?.topics.length) return null;

    const TravelsBreakpoint = isMobile ? MobileTravels : DesktopTravels;

    return (
      <Section className={styles.travels} {...!isMobile && { withBackground: true }}>
        <Indent bottom={isMobile ? '28rem' : '66rem'}>
          <SectionHeader link='/travels' count={content.count}>
            Путешествия
          </SectionHeader>
        </Indent>
        <TravelsBreakpoint
          topics={content.topics}
          activeIndex={this.state.activeIndex}
          onActiveChange={this.changeActiveIndex}
        />
      </Section>
    );
  }
}

const EnhancedTravels = withBreakpoint(Travels);

EnhancedTravels.requiredPayloadFields = requiredPayloadFields;
EnhancedTravels.requiredPayloadImports = requiredPayloadImports;

export default EnhancedTravels;
