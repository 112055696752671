import PropTypes from 'prop-types';

import skip from 'core/resolver/skip';
import bindProps from 'core/components/bindProps';

import Page from 'core/components/Page';
import SearchPage from 'core/components/SearchPage';

import { withBreakpoint } from 'core/components/breakpoint';

import RubricHeader from 'site/components/RubricHeader';

import { CardHorizontalS, CardHorizontalTiny } from 'site/cards/CardHorizontal';

import FoundBlock from './Found';

import styles from './index.styl';


const filters = [
  { key: 'topic_type', value: 'article', title: 'Статьи' },
  { key: 'topic_type', value: 'guide', title: 'Гайды' },
  { key: 'topic_type', value: 'news', title: 'Новости' },
  { key: 'topic_type', value: 'travel', title: 'Путешествия' },
  { key: 'topic_type', value: 'test_drive', title: 'Тест-драйвы' },
  { key: 'topic_type', value: 'car_review', title: 'Обзоры' },
  { key: 'topic_type', value: 'car_selection', title: 'Подборки' },
];

const commonProps = {
  lineClamp: 10,
  showAltHeadline: true,
};

const CardHorizontalMob = bindProps(commonProps)(CardHorizontalTiny);
const CardHorizontalDesk = bindProps(commonProps)(CardHorizontalS);

function Search({ isMobile }) {
  return (
    <Page>
      <RubricHeader title='Поиск по сайту' />
      <div className={styles.wrapper}>
        <SearchPage
          filters={filters}
          card={isMobile ? CardHorizontalMob : CardHorizontalDesk}
          tagSize={isMobile ? 'medium' : 'small'}
          tagTypeActive='primary'
          foundTextBlock={FoundBlock}
          interitemSpacing={isMobile ? 16 : 60}
          iconSize={16}
        />
      </div>
    </Page>
  );
}

Search.propTypes = {
  isMobile: PropTypes.bool,
};

export default skip(withBreakpoint(Search));
