import cx from 'classnames';
import PropTypes from 'prop-types';

import Texts from 'core/components/Texts';

import styles from './index.styl';

export default function SberScore({ sberScore, className }) {
  if (!sberScore) return null;

  return (
    <div className={cx('score', className)}>
      <Texts type='bodySmall'>Сбер-Score</Texts>
      <Texts type='title3' className={styles.scoreValue}>{sberScore}%</Texts>
    </div>
  );
}

SberScore.propTypes = {
  className: PropTypes.string,
  sberScore: PropTypes.number,
};
