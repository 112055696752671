import { Mobile, Desktop } from 'core/components/breakpoint';

import CoverDesktop from './Desktop';
import CoverMobile from './Mobile';

export default function TopicCover({ ...props }) {
  return (
    <>
      <Mobile><CoverMobile {...props} /></Mobile>
      <Desktop><CoverDesktop {...props} /></Desktop>
    </>
  );
}
