import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';

import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import MapPointer from 'site/icons/MapPointer.svg';

import { PILES_DATA } from '../constants';

import styles from './index.styl';


function PVPMenuMobile({ theme }) {
  const {
    colors: {
      green300,
      white,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${white}

          &:active
            .${styles.text}
              background-image linear-gradient(97deg, ${green300} 0%, #BFDDFF 103%)            

            :global(svg)
              color ${green300}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.pvpMenuMobile}>
      {PILES_DATA.map(renderData => {
        const {
          buttonText,
          slug,
        } = renderData;

        return (
          <Link
            type='primary'
            to={'/' + slug}
            className={scope.wrapClassNames(styles.link)}
            title={buttonText}
            key={slug}
          >
            <MapPointer />
            <Texts type='bodySmall' className={scope.wrapClassNames(styles.text)}>
              {buttonText}
            </Texts>
          </Link>
        );
      })}
      <scope.styles />
    </div>
  );
}

PVPMenuMobile.propTypes = {
  menus: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      green300,
      white,
    },
  }`),
};

export default withTheme(PVPMenuMobile);
