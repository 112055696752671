import PropTypes from 'prop-types';

import TableRow from './TableRow';

import styles from './index.styl';


export default function InfoTable({ tableData }) {
  return (
    <div className={styles.table}>
      {tableData.length > 0 && tableData.map((item, index) => (
        <TableRow
          key={index}
          label={item.label}
          value={item.value}
        />
      ))}
    </div>
  );
}

InfoTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    })
  ),
};
