import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { Indent } from 'core/components/Wrappers';
import Texts from 'core/components/Texts';

import RatingTable from 'site/components/RatingTable';
import Reactions from 'site/components/Reactions';

import { carReviewMetaAttributes } from 'site/utils/prop-types/common';


function CarReview({ carReviewMeta, isMobile, className, showTableNote, targetId }) {
  if (!carReviewMeta) return null;

  return (
    <div className={className}>
      <RatingTable ratingTable={carReviewMeta.rating_table} />
      <Indent bottom={isMobile ? '10rem' : '20rem'} />
      <Reactions targetId={targetId} themeType='secondary' />
      {showTableNote && (
        <Indent top={isMobile ? '32rem' : '64rem'}>
          <Texts type='bodySmall'>*Народный рейтинг формируется на базе Rambler Опросов</Texts>
        </Indent>
      )}
      <Indent bottom={isMobile ? '6rem' : '64rem'} />
    </div>
  );
}

CarReview.propTypes = {
  carReviewMeta: carReviewMetaAttributes,
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  isMobile: PropTypes.bool,
  /**
   * Флаг для показа примечания к таблице
   */
  showTableNote: PropTypes.bool,
  /**
   * ID топика для привязки реакций к конкретному топику
   */
  targetId: PropTypes.string,
};

const CarReviewWithHOCs = withBreakpoint(CarReview);
CarReviewWithHOCs.displayName = 'CarReview';

export { CarReview as StorybookComponent };

export default CarReviewWithHOCs;
