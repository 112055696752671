import PropTypes from 'prop-types';

import themeSwitcher from 'core/components/themeSwitcher';

import MarkdownWrapper from 'core/components/MarkdownWrapper';
import Texts from 'core/components/Texts';

import RadialBackground from 'site/components/RadialBackground';
import GradientBackground from 'site/components/GradientBackground';

import styles from './index.styl';

function Incut(props) {
  const { block, parentProps } = props;

  const body = block.attributes.body;
  const { isDesktop } = parentProps;

  const Content = (
    <Texts
      type={isDesktop ? 'accent' : 'title4'}
      className={styles.content}
    >
      <MarkdownWrapper>
        {body}
      </MarkdownWrapper>
    </Texts>
  );

  if (isDesktop) {
    return (
      <RadialBackground className={styles.incut}>
        {Content}
      </RadialBackground>
    );
  }

  return (
    <GradientBackground className={styles.gradient}>
      <div className={styles.incut}>
        {Content}
      </div>
    </GradientBackground>
  );
}

Incut.propTypes = {
  parentProps: PropTypes.object,
  block: PropTypes.object,
};

export default themeSwitcher('dark')(Incut);
