import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import SmartImage from 'core/components/SmartImage';
import Texts from 'core/components/Texts';

import Button from 'site/components/Button';
import CarReview from 'site/components/CarReview';
import GradientMask from 'site/components/GradientMask';

import CarPrice from '../CarPrice';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'car_review_meta',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
  car_review_meta: {
    price_from: '',
    price_to: '',
  },
});

function DesktopReview({ theme, topic }) {
  if (!topic) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = topic.attributes || {};

  const {
    car_review_meta: carReviewMeta,
    image: {
      versions,
    },
  } = relationships(topic) || {};

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.cardBody}
        .${styles.announce}
          font-family ${theme.fonts.text}
      `}</style>
      <div className={styles.cardHeader}>
        <div className={styles.headlines}>
          <Texts type='title3' className={styles.title}>{listHeadline || headline}</Texts>
          {altHeadline && <div className={styles.altHeadline}>{altHeadline}</div>}
        </div>
        <CarPrice carReviewMeta={carReviewMeta} />
      </div>

      <div className={styles.cardBody}>
        <CarReview
          carReviewMeta={carReviewMeta}
          targetId={topic.id}
          className={styles.carReview}
        />
        <div className={styles.rightColumn}>
          {versions.original && (
            <div className={styles.imageWrapper}>
              <GradientMask className={styles.image} containerType='topicCoverDesktop'>
                <SmartImage
                  versions={versions}
                  maxWidth={707}
                  version='car_avatar'
                />
              </GradientMask>
            </div>
          )}
          <Button
            className={styles.button}
            link={link}
            size='large'
            minWidth='100%'
          >
            Читать обзор
          </Button>
        </div>
      </div>
    </div>
  );
}

DesktopReview.propTypes = {
  theme: PropTypes.object,
  topic: modelPropTypes(topicAttributes),
};

const Card = withTheme(DesktopReview);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;
