import PropTypes from 'prop-types';
import cx from 'classnames';

import themeSwitcher from 'core/components/themeSwitcher';

import { NegativeMobile } from 'core/components/Wrappers';
import withTheme from 'core/components/theme';

import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


function RubricHeader(props) {
  const {
    title,
    footerContent,
    showGradientMask,
    theme,
    className,
    headlineSize,
  } = props;

  const headlineSmaller = headlineSize === 'smaller';
  const headlineNormal = headlineSize === 'normal';

  let [markedText, ...restTitle] = title.split(' ');
  if (markedText.length < 4 && restTitle?.length > 0) {
    markedText =  `${markedText} ${restTitle[0]}`;
    restTitle = restTitle.slice(1);
  }

  const GradientWrapper = showGradientMask ? GradientMask : 'div';

  return (
    <NegativeMobile>
      <GradientWrapper
        className={cx(
          styles.rubricHeader,
          className,
          headlineSmaller && styles._headlineSmaller,
          headlineNormal && styles._headlineNormal,
        )}
      >
        <style jsx>{`
          .${styles.headline}
            color ${theme.colors.primary}
            font-family ${theme.fonts.display}
        `}</style>
        <h1 className={styles.headline}>
          {markedText && (
            <>
              <mark>{markedText}</mark><br />
            </>
          )}
          <span className={styles.restTitle}>
            {restTitle.join(' ')}
          </span>
        </h1>
        {footerContent}
      </GradientWrapper>
    </NegativeMobile>
  );
}

RubricHeader.propTypes = {
  /** Кастомный класс для хедера. */
  className: PropTypes.string,

  /** Заголовок страницы. */
  title: PropTypes.string.isRequired,

  /** Нижняя часть шапки. */
  footerContent: PropTypes.node,

  /** Оборачивать ли обложку в GradientMask */
  showGradientMask: PropTypes.bool,

  /** @ignore */
  theme: PropTypes.object,

  /** @ignore */
  isMobile: PropTypes.bool,

  /**
   * По умолчанию RubricHeader стилизован под страницы рубрик.
   * На страницах PVP, legal, author, about заголовок может быть трансформирован
   */
  headlineSize: PropTypes.oneOf(['normal', 'smaller']),
};

RubricHeader.defaultProps = {
  title: '',
  headlineSize: 'normal',
};

const RubricHeaderWithHOCs = themeSwitcher('dark')(withTheme(RubricHeader));
RubricHeaderWithHOCs.displayName = 'RubricHeader';

export default RubricHeaderWithHOCs;

export { RubricHeader as StorybookComponent };
export { default as PVPLabels } from './PVPLabels';
