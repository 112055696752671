import PropTypes from 'prop-types';
import cx from 'classnames';

import readMore from 'core/components/TopicContent/blocks/readMore';
import ThemeSwitch from 'core/components/ThemeSwitch';
import bindProps from 'core/components/bindProps';

import modelPropTypes, { readMoreAttributes } from 'core/utils/prop-types/model';

import GradientBackground from 'site/components/GradientBackground';
import SectionHeader from 'site/components/SectionHeader';
import { CardVerticalM } from 'site/cards/CardVertical';
import CardForDrumAnimation from 'site/cards/CardForDrumAnimation';
import { CardHorizontalS } from 'site/cards/CardHorizontal';
import CardRect1 from 'site/cards/CardRect1';
import CardFullwidth from 'site/cards/CardFullwidth';

import styles from './index.styl';


const CardRect1Desktop = bindProps({ hideAltHeadline: true })(CardRect1);

export default function ReadMore({ block, parentProps }) {
  const contentArr = block && block.attributes ? block.attributes.topic_ids : [];
  const contentLength = contentArr.length;
  const moreThanTwoTopics = contentLength > 2;

  if (!contentLength) {
    return null;
  }

  if (parentProps.isMobile) {
    return (
      <GradientBackground className={cx(styles.gradient, moreThanTwoTopics && styles._moreThanTwo)}>
        <div className={styles.readMore}>
          <SectionHeader>Вам может быть <mark>интересно</mark></SectionHeader>
          <ThemeSwitch name={moreThanTwoTopics ? 'dark' : 'light'}>
            <div className={styles.body}>
              {readMore(
                block,
                {
                  singleCard: CardVerticalM,
                  feedCard: CardVerticalM,
                  drumCard: CardForDrumAnimation,
                }
              )}
            </div>
          </ThemeSwitch>
        </div>
      </GradientBackground>
    );
  }

  return (
    <GradientBackground className={styles.gradient}>
      <div className={styles.readMore}>
        <SectionHeader titleType='accent'>Вам может быть <mark>интересно</mark></SectionHeader>
        <div className={styles.body} data-qa='read-more'>
          {readMore(
            block,
            {
              singleCard: CardFullwidth,
              feedCard: CardHorizontalS,
              drumCard: CardRect1Desktop,
            }
          )}
        </div>
      </div>
    </GradientBackground>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
  parentProps: PropTypes.object,
};
