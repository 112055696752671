import { useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { getVisitedPVP, setVisitedPVP } from 'site/utils/cookie';


function observerCallback(entry, observer, setVisitedCallback) {
  if (entry.isIntersecting) {
    setVisitedCallback();
    observer && observer.disconnect();
  }
}

export default function VisitedTopicAnchor({ topicId }) {
  const isVisitedTopic = useMemo(() => {
    const visitedTopics = getVisitedPVP();
    return visitedTopics.includes(topicId);
  }, [topicId]);

  const lastEl = useRef(null);

  const setVisitedCallback = useCallback(() => {
    if (isVisitedTopic) return;

    setVisitedPVP(topicId);
  }, [topicId, isVisitedTopic]);

  useEffect(() => {
    if (isVisitedTopic || !lastEl?.current) return () => null;

    const observer = new IntersectionObserver(([entry], innerObserver) => observerCallback(entry, innerObserver, setVisitedCallback));
    observer.observe(lastEl.current);

    return () => observer.disconnect();
  }, [isVisitedTopic, setVisitedCallback]);

  if (isVisitedTopic) return null;

  return <div ref={lastEl} />;
}

VisitedTopicAnchor.propTypes = {
  topicId: PropTypes.string,
};
