import { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import Swipe, { Slide } from 'core/components/Swipe';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';
import BlockNavigation from 'site/components/BlockNavigation';

import MobileReview from './Mobile';
import DesktopReview from './Desktop';

import styles from './index.styl';


function Reviews({ content, isDesktop }) {
  const {
    topics,
    count,
  } = content;

  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = useCallback(({ realIndex }) => {
    setActiveIndex(realIndex);
  }, []);

  const swipeEl = useRef(null);

  const nextHandler = useCallback(() => swipeEl.current.next(), []);
  const prevHandler = useCallback(() => swipeEl.current.prev(), []);

  const goToHandler = useCallback(index => {
    swipeEl.current.goto(index);
  }, []);

  if (topics?.length === 0) return null;

  return (
    <Section className={styles.review} withBackground={isDesktop}>
      <div className={styles.sectionHeader}>
        <SectionHeader link='/reviews' count={count}>
          Народный рейтинг авто
        </SectionHeader>
      </div>
      <Desktop>
        <Swipe
          loop
          ref={swipeEl}
          onActiveIndexChange={onActiveIndexChange}
        >
          {topics.map((topic) => (
            <Slide
              width='100%'
              key={topic.id}
              additionalClass={styles.wrapperClass}
            >
              <DesktopReview topic={topic} />
            </Slide>
          ))}
        </Swipe>
        <div className={styles.pagination}>
          <BlockNavigation
            topics={topics}
            activeIndex={activeIndex}
            prevHandler={prevHandler}
            nextHandler={nextHandler}
            goToHandler={goToHandler}
          />
        </div>
      </Desktop>
      <Mobile>
        <MobileReview
          topics={topics}
          activeIndex={activeIndex}
          onActiveChange={onActiveIndexChange}
        />
      </Mobile>
    </Section>
  );
}

Reviews.propTypes = {
  content: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    count: PropTypes.number,
  }),
  isDesktop: PropTypes.bool,
};

Reviews.displayName = 'Reviews';

export { Reviews as StorybookComponent };

export default withBreakpoint(Reviews);

