import PropTypes from 'prop-types';

import Texts from 'core/components/Texts';

import styles from './index.styl';


export default function TableRow({ label, value, isMobile }) {
  return (
    <>
      <Texts type={isMobile ? 'title4' : 'buttonCTA'} className='header'>{label}:</Texts>
      <Texts className={styles.value}>{value}</Texts>
    </>
  );
}

TableRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  isMobile: PropTypes.bool,
};
