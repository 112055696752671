import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import { Indent } from 'core/components/Wrappers';

import SberScore from 'site/components/SberScore';
import TravelMeta from 'site/components/TravelMeta';
import CarReview from 'site/components/CarReview';

import resolveRelationships from 'core/utils/relationships';

import { TRAVEL_SLUG, TEST_DRIVE_SLUG, CAR_REVIEW_SLUG } from 'site/constants';

import styles from './index.styl';


/**
 * Список полей без которых TopicHeader не будет отрендерен.
 */
export const requiredImports = [
  'test_drive_meta',
  'travel_meta',
  'car_review_meta',
];

export const requiredFields = [
  'alternative_headline',
  'announce',
];

const relationships = resolveRelationships(requiredImports, null);

function Content({ content, isMobile }) {
  const {
    alternative_headline: altHeadline,
    announce,
    topic_type: topicType,
  } = content.attributes;

  const {
    test_drive_meta: testDriveMeta,
    travel_meta: travelMeta,
    car_review_meta: carReviewMeta,
  } = relationships(content);

  const indent = (isMobile ? 24 : 32) + 'rem';

  const altHeadlineTitle = (
    <Texts
      tag='h3'
      type={isMobile ? 'title4' : 'title2'}
      className={styles.altHeadline}
    >
      {altHeadline}
    </Texts>
  );
  const announceContent = <div data-qa='announce'>{announce}</div>;

  const TravelContent = (
    <>
      {altHeadlineTitle}
      <Indent bottom={indent} />
      <TravelMeta travelMeta={travelMeta} isTopicPage />
      <Indent bottom={indent} />
      {announceContent}
    </>
  );

  const TestDriveContent = (
    <>
      {altHeadlineTitle}
      <Indent bottom={indent} />
      <SberScore sberScore={testDriveMeta?.sber_score} />
      <Indent bottom={indent} />
      {announceContent}
    </>
  );

  const CardReviewContent = (
    <CarReview
      showTableNote
      carReviewMeta={carReviewMeta}
      targetId={content.id}
    />
  );

  const DefaultContent = (
    <>
      {altHeadlineTitle}
      <Indent bottom={indent} />
      {announceContent}
    </>
  );

  switch (topicType) {
    case TRAVEL_SLUG:
      return <>{TravelContent}</>;

    case TEST_DRIVE_SLUG:
      return <>{TestDriveContent}</>;

    case CAR_REVIEW_SLUG:
      return <>{CardReviewContent}</>;

    default:
      return <>{DefaultContent}</>;
  }
}

Content.propTypes = {
  content: PropTypes.object,
  isMobile: PropTypes.bool,
};

const ContentWithHOCs = withBreakpoint(withTheme(Content));
Content.displayName = 'Content';

export { Content as StorybookComponent };

export default ContentWithHOCs;
