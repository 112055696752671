import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import { dateFormat } from 'core/utils/dates';

import styles from './index.styl';

function PublishedDate({ publishedAt, isMobile }) {
  const time = dateFormat(publishedAt, 'HH:mm');
  const date = dateFormat(publishedAt, `dd ${isMobile ? 'MMM' : 'MMMM'}  y`);

  return (
    <div className={styles.publishedDate}>
      {time + ', ' + date}
    </div>
  );
}

PublishedDate.propTypes = {
  publishedAt: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PublishedDate);
