export const host = 'sberauto-journal.ru';
export const siteUrl = 'https://' + host;

export const basicSlug = '[a-z0-9\-_]+';
export const topicSlug = `${basicSlug}\/[a-z0-9\-_.]+`;

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 16;
export const WRAPPER_SIDE_INDENT_MOBILE = 12;

// limits
export const RUBRICS_PAGE_LIMIT = 9;
export const TAG_PAGE_LIMIT = 22;

// sizes
export const LAYOUT_MAX_WIDTH = 1664;
export const LAYOUT_MAX_WIDTH_MOBILE = 600;
export const CONTENT_AREA = 660;
export const RIGHT_COLUMN_WIDTH = 300;

export const datePattern = 'dd MMMM yyyy HH:mm';

/**
 * Слаги раздела PVP
 */
export const POKUPKA_SLUG = 'want-to-buy';
export const VLADENIE_SLUG = 'ownership';
export const PRODAGA_SLUG = 'want-to-sell';

/**
 * PVP-переменные топиков
 */
export const PVP_NAV = 'pvp-scroll-to';

/**
 * Типы топиков
 */
export const WIZARD_SLUG = 'wizard';
export const PVP_SLUG = 'pvp';
export const CAR_REVIEW_SLUG = 'car_review';
export const TRAVEL_SLUG = 'travel';
export const TEST_DRIVE_SLUG = 'test_drive';
