import cx from 'classnames';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';


function RatingTable(props) {
  const {
    ratingTable,
    className,
    isMobile,
    theme,
  } = props;

  if (!ratingTable) return null;

  return (
    <div className={cx(styles.ratingTable, className)}>
      <style jsx>{`
        .${styles.ratingTable}
          font-family ${theme.fonts.display}
      `}</style>
      <table>
        <thead>
          <tr>
            <th>
              <Texts type={isMobile ? 'title2' : 'title4'}>Важные характеристики</Texts>
            </th>
            <th>Да</th>
            <th>Нет</th>
          </tr>
        </thead>
      </table>
      <MarkdownWrapper>
        {ratingTable}
      </MarkdownWrapper>
    </div>
  );
}

RatingTable.propTypes = {
  className: PropTypes.string,
  /**
   * Данные в формате markdown для рендера таблицы
   */
  ratingTable: PropTypes.string,
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(RatingTable));
