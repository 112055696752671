import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import GradientMask from 'site/components/GradientMask';

import bindPropsHOC from 'core/components/bindProps';
import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import { imageMaxWidthValidator } from 'site/utils/prop-types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardVertical(props) {
  const {
    content,
    imageMaxWidth,
    imageRatio,
    imageVersion,
    titleType,
    backgroundColor,
    showAltHeadline,
    cardRadius,
    coverRadius,
    lineClampHeadline,
    lineClampAltHeadline,
    bodyPadding,
    theme,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const colorFromTheme = theme.colors[backgroundColor];

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardVertical}
          max-width ${imageMaxWidth}px
          border-radius ${cardRadius ? cardRadius + 'rem' : 'unset'}

          &.${styles._hasBackground}
            background-color ${colorFromTheme}

        .${styles.cover}
          border-radius ${coverRadius ? coverRadius + 'rem' : 'unset'}

        .${styles.body}
          padding ${bodyPadding}

        .${styles.headline}
          .${styles._lineClampHeadline} &
            -webkit-line-clamp ${lineClampHeadline}

        .${styles.altHeadline}
          .${styles._lineClampAltHeadline} &
            -webkit-line-clamp ${lineClampAltHeadline}
      `}</style>
    </scope>
  );

  const title = listHeadline || headline;

  return (
    <Link
      to={link}
      title={title}
      type='primary'
      className={scope.wrapClassNames(
        styles.cardVertical,
        colorFromTheme && styles._hasBackground,
        lineClampHeadline && styles._lineClampHeadline,
        lineClampAltHeadline && styles._lineClampAltHeadline,
      )}
    >
      {versions.original && (
        <div className={scope.wrapClassNames(styles.cover)}>
          <GradientMask hideEllipsis>
            <SmartImage
              versions={versions}
              version={imageVersion}
              maxWidth={imageMaxWidth}
              aspectRatio={imageRatio}
            />
          </GradientMask>
        </div>
      )}
      <div className={scope.wrapClassNames(styles.body)}>
        <Texts type={titleType} className={scope.wrapClassNames(styles.headline)}>
          <MarkdownWrapper children={title} inline />
        </Texts>
        {showAltHeadline && (
          <Texts className={styles.altHeadline}>
            <MarkdownWrapper children={altHeadline} inline />
          </Texts>
        )}
      </div>
      <scope.styles />
    </Link>
  );
}

CardVertical.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),

  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: imageMaxWidthValidator,

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /** Переобложка изображения, которая будет использоваться в карточке вместо `original` */
  imageVersion: PropTypes.string,

  /** Тип заголовка по схеме шрифтов в теме сайта */
  titleType: PropTypes.oneOf(['title4', 'title3']),

  /** Цвет фона */
  backgroundColor: PropTypes.oneOf(['gray100', 'none']),

  /** Показать подзаголовок */
  showAltHeadline: PropTypes.bool,

  /** Отступы для текстового блока */
  bodyPadding: PropTypes.string,

  /** Скругление у картинки */
  coverRadius: PropTypes.number,

  /** Скругление у карточки (например если есть фон) */
  cardRadius: PropTypes.number,

  /** Обрезает заголовок многоточием и устанавливает максимальное кол-во строк */
  lineClampHeadline: PropTypes.number,

  /** Обрезает подзаголовок многоточием и устанавливает максимальное кол-во строк */
  lineClampAltHeadline: PropTypes.number,

  /** @ignore */
  theme: PropTypes.object,
};

CardVertical.defaultProps = {
  imageRatio: 1.69,
  imageMaxWidth: 750,
  backgroundColor: 'none',
};

const Card = withTheme(CardVertical);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export const CardVerticalS = bindPropsHOC({
  showAltHeadline: true,
  titleType: 'title3',
  bodyPadding: '16rem 0 0',
  coverRadius: 20,
  lineClampHeadline: 4,
})(Card);

export const CardVerticalTiny = bindPropsHOC({
  imageRatio: 1.64,
  imageMaxWidth: 375,
  coverRadius: 16,
  lineClampHeadline: 3,
  bodyPadding: '12rem 0 0',
  titleType: 'title4',
})(Card);

export const CardVerticalM = bindPropsHOC({
  imageRatio: 1.52,
  imageMaxWidth: 514,
  coverRadius: 40,
  cardRadius: 40,
  backgroundColor: 'gray100',
  showAltHeadline: true,
  bodyPadding: '24rem 32rem 28rem',
  titleType: 'title4',
})(Card);

export default Card;
