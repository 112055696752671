import bindProps from 'core/components/bindProps';

import Ad from 'core/components/Ad/Adfox';


const Adfox = bindProps({
  name: 'Adfox',
  ownerId: 11629075,
  params: {
    pp: 'g',
    ps: 'idbp',
    p2: 'jdyb',
  },
})(Ad);

export default Adfox;
