import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import styles from './index.styl';

/**
 * Фоновое изображение для главной страницы.
 *
 * По документации оно будет меняться 1-2 раза в месяц из админки(TODO реализовать)
 */
function MainCover({ children, className }) {
  return (
    <div className={cx(styles.mainCover, className)}>
      {children}
    </div>
  );
}

MainCover.propTypes = {
  className: PropTypes.string,
};

const MainCoverWithHOCs = withTheme(MainCover);
MainCoverWithHOCs.displayName = 'MainCover';

export default MainCoverWithHOCs;

export { MainCover as StorybookComponent };
