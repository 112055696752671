import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import EmptyWrapper from 'core/components/EmptyWrapper';
import themeSwitcher from 'core/components/themeSwitcher';

import MainCover from './MainCover';
import PaperBackground from './PaperBackground';
import SolidBackground from './SolidBackground';
import MajorTitle from './MajorTitle';

import styles from './index.styl';

const mapTypes = {
  paperBackground: PaperBackground,
  mainCover: MainCover,
  solidBackground: SolidBackground,
};

function MajorScreen(props) {
  const {
    type,
    children,
    className,
    title,
    description,
    negativeMobile,
    ...otherProps
  } = props;

  const Component = mapTypes[type] || EmptyWrapper;

  const isRenderChildren = !title;

  const child = isRenderChildren
    ? children
    : <MajorTitle title={title} description={description} />;

  return (
    <>
      <style jsx>{`
        .${styles.majorScreen}
          color ${otherProps.theme.colors.primary}
      `}</style>

      <Component
        className={cx(
          styles.majorScreen,
          negativeMobile && styles.negativeMobile,
          className,
          styles['_' + type]
        )}
        {...otherProps}
      >
        {child}
      </Component>
    </>
  );
}


MajorScreen.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.oneOf(['paperBackground', 'mainCover', 'solidBackground']).isRequired,
  title: PropTypes.node,
  description: PropTypes.string,
  negativeMobile: PropTypes.bool,
};

MajorScreen.defaultProps = {
  type: 'paperBackground',
};


const MajorScreenWithHOCs = themeSwitcher('dark')(withTheme(withBreakpoint(MajorScreen)));
MajorScreenWithHOCs.displayName = 'MajorScreen';

export default MajorScreenWithHOCs;

export { MajorScreen as StorybookComponent };
