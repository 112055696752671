import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function ScrollerVertical({ children, className, showTrack }) {
  return (
    <div className={cx(styles.scrollerVertical, { [styles.withTrack]: showTrack }, className)}>
      {children}
    </div>
  );
}

ScrollerVertical.propTypes = {
  className: PropTypes.string,
  showTrack: PropTypes.bool,
};
