import PropTypes from 'prop-types';
import cx from 'classnames';

import coreMarkdown from 'core/components/TopicContent/blocks/markdown';
import { Indent } from 'core/components/Wrappers';

import resolveRelationships from 'core/utils/relationships';

import Tags from 'site/components/Tags';
import TopicSection from 'site/components/TopicSection';

import VisitedTopicAnchor from './VisitedTopicAnchor';

import styles from './index.styl';


const relationships = resolveRelationships(['tags'], {});

export default function Markdown(props) {
  const {
    block,
    parentProps,
    ctx,
  } = props;

  const {
    topic: {
      content: {
        id: topicId,
      },
    },
    showTopicHeader,
    isPVP,
    lastWidgetPosition,
    lastMarkdownPosition,
    theme,
    isMobile,
  } = parentProps;

  const { title4, body } = theme.texts;

  const { tags } = relationships(parentProps.topic.content);

  const currentPosition = block.position;
  const isFirstWidget = currentPosition === 0;

  const isLastMarkdownWidget = currentPosition === lastMarkdownPosition;

  const showTags = isLastMarkdownWidget && tags.length > 0;
  const collapsed = isFirstWidget && showTopicHeader;

  return (
    <div className={cx(styles.markdown, currentPosition === lastWidgetPosition && styles._isLastWidget)}>
      <style jsx>{`
        .${styles.markdown}
          :global(.mobile) &
            :global(h2.heading)
              font ${title4.font}
              color ${title4.color}
              letter-spacing ${title4.letterSpacing || 'none'}

          :global(ol > li:has(ul))
          :global(ol > li:has(ol))
            font ${title4.font}
            color ${title4.color}
            letter-spacing ${title4.letterSpacing || 'none'}

          :global(ol > li ul)
          :global(ol > li ol)
            font ${body.font}
            color ${body.color}
            letter-spacing ${body.letterSpacing || 'none'}
      `}</style>
      <TopicSection
        disableTopRadius={isFirstWidget || collapsed}
        collapseTop={collapsed}
        className={styles.topicSection}
      >
        {coreMarkdown(block, parentProps, ctx)}
        {showTags && (
          <Indent top={isMobile ? '24rem' : '56rem'}>
            <Tags tags={tags} />
          </Indent>
        )}
        {isLastMarkdownWidget && isPVP && <VisitedTopicAnchor topicId={topicId} />}
      </TopicSection>
    </div>
  );
}

Markdown.propTypes = {
  block: PropTypes.object,
  parentProps: PropTypes.object,
  ctx: PropTypes.object,
};
