import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Clock from 'site/icons/Clock';

import { timeDurationInSeconds } from 'core/utils/dates';

import styles from './index.styl';

function ReadDuration({ readDuration, isMobile }) {
  const duration = timeDurationInSeconds(readDuration || '');
  const readTime = Math.ceil(duration / 60);

  if (!readTime) return null;

  const iconSize = isMobile ? '12rem' : '20rem';

  return (
    <div className={styles.readDuration}>
      <Clock width={iconSize} height={iconSize} />
      {readTime + ' мин.'}
    </div>
  );
}

ReadDuration.propTypes = {
  readDuration: PropTypes.string,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const ReadDurationWithHOCs = withBreakpoint(ReadDuration);

export { ReadDuration as StorybookComponent };

export default ReadDurationWithHOCs;
