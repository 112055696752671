import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';
import ThemeSwitch from 'core/components/ThemeSwitch';

import Texts from 'core/components/Texts';

import Section from 'site/components/Section';
import Button from 'site/components/Button';

import Authors from './Authors';

import styles from './index.styl';


const LIMIT = 6;

function OurTeam({ isMobile }, { bebopApi }) {
  const [visibleAuthor, setVisibleAuthor] = useState(null);

  const [authors, setAuthors] = useState(Array(LIMIT).fill({
    attributes: {},
  }));

  useEffect(() => {
    bebopApi.getAboutPage({
      include: 'authors',
    })
      .then(denormalizeData)
      .then(data => data.relationships.authors?.data?.slice(0, LIMIT))
      .then(data => {
        setAuthors(data);
      })
      .catch(() => []);
  }, []); // eslint-disable-line

  const ButtonComp = (
    <div className={styles.buttonWrapper}>
      <Button
        className={styles.btn}
        link='/about'
        size='large'
        {...isMobile && { minWidth: '100%' }}
      >
        Познакомиться поближе
      </Button>
    </div>
  );

  return (
    <Section className={styles.wrapper} withBackground>
      <ThemeSwitch name={isMobile ? 'dark' : 'light'}>
        <div className={styles.square}>
          <div className={styles.ring1} />
          <div className={styles.ring2} />
          <div className={styles.ring3} />
          <Authors
            authors={authors}
            visibleAuthor={visibleAuthor}
            setVisibleAuthor={setVisibleAuthor}
          />
        </div>
      </ThemeSwitch>
      <div className={styles.textContent}>
        <Texts className={styles.title} type={isMobile ? 'accent' : 'title3'}>
          Наша редакция
        </Texts>
        {!isMobile && ButtonComp}
      </div>
      {isMobile && ButtonComp}
    </Section>
  );
}

OurTeam.contextTypes = {
  bebopApi: PropTypes.object,
};

OurTeam.propTypes = {
  isMobile: PropTypes.bool,
  authors: PropTypes.array,
};

export default withBreakpoint(OurTeam);
