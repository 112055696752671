import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';

import Section from 'site/components/Section';
import SectionHeader from 'site/components/SectionHeader';

import ArticlesFeed from 'site/components/ArticlesFeed';
import News from 'site/components/News';

import styles from './index.styl';


function NewsAndArticles(props) {
  const {
    articles: {
      topics: articlesTopics,
      count: articlesCount,
    },
    news: {
      topics: newsTopics,
      count: newsCount,
    },
    isDesktop,
  } = props;

  if (articlesTopics.length + newsTopics.length === 0) return null;

  const showArticles = articlesTopics.length > 0;
  const showNews = newsTopics.length > 0;

  return (
    <Section className={styles.wrapper} withBackground={isDesktop}>
      {showArticles && (
        <div className={styles.articles}>
          <SectionHeader link='/articles' count={articlesCount}>Cтатьи</SectionHeader>
          <ArticlesFeed content={articlesTopics} />
        </div>
      )}
      {showNews && (
        <div className={styles.news}>
          <SectionHeader link='/news' count={newsCount}>Новости</SectionHeader>
          <News content={newsTopics} addRightIndent />
        </div>
      )}
    </Section>
  );
}

NewsAndArticles.propTypes = {
  articles: PropTypes.object,
  news: PropTypes.object,
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(NewsAndArticles);
