import PropTypes from 'prop-types';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { withBreakpoint } from 'core/components/breakpoint';

import { Indent } from 'core/components/Wrappers';

import SectionNews from 'site/components/SectionNews';
import MoreArticles from 'site/components/MoreArticles';
import Paginator from 'site/components/Paginator';


function RubricTopics(props) {
  const {
    apiParams,
    topicsFromList,
    topics,
    firstTitle,
    secondTitle,
    isMobile,
    startOffset,
    filteredCount,
  } = props;

  const indent = isMobile ? '32rem' : '80rem';

  const [
    firstFromList,
    secondFromList,
    thirdFromList,
  ] = topicsFromList;

  // Если первый топик в листе отсутствует, то отображаем в первом блоке со скроллом на 1 топик меньше,
  // чтобы в последнем блоке было 4 топика
  const firstBlock = firstFromList ? [firstFromList, ...topics.slice(0, 8)] : topics.slice(0, 7);
  const nextBlock = thirdFromList ? [thirdFromList, ...topics.slice(8)] : topics.slice(7);

  return (
    <>
      {firstBlock.length > 0 && (
        <Indent bottom={indent}>
          <SectionNews content={{ topics: firstBlock }} sectionTitle={firstTitle} />
        </Indent>
      )}
      {nextBlock.length > 0 && (
        <Indent bottom={indent}>
          <MoreArticles
            fullWidthTopic={secondFromList}
            articles={nextBlock}
            sectionTitle={secondTitle}
            showPublishedAt
            showAuthors
          />
        </Indent>
      )}
      <Indent bottom={isMobile ? '48rem' : '80rem'}>
        <Paginator
          apiParams={apiParams}
          startOffset={startOffset}
          filteredCount={filteredCount}
        />
      </Indent>
    </>
  );
}

RubricTopics.propTypes = {
  isMobile: PropTypes.bool,
  apiParams: PropTypes.object,
  firstTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  topicsFromList: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)).isRequired,
  startOffset: PropTypes.number,
  filteredCount: PropTypes.number,
};

export default withBreakpoint(RubricTopics);
