import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import H4 from 'core/components/H4';
import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';
import Texts from 'core/components/Texts';

import themePropTypes from 'core/utils/prop-types/theme';
import modelPropTypes, { personAttributes } from 'core/utils/prop-types/model';

import styles from './index.styl';


function OurRedaction({ isMobile, authors, theme }) {
  return (
    <div className={styles.redaction}>
      <style jsx>{`
        .${styles.editorBody}
          font ${theme.texts.bodySmall.font}
      `}</style>
      {authors.map(author  => {
        const {
          attributes: {
            avatar,
            name,
            job_title: jobTitle,
            slug,
          },
        } = author;

        const avatarVersions = avatar?.attributes?.versions;

        return (
          <div key={slug} className={styles.editor}>
            <div className={styles.container}>
              <div className={styles.avatar}>
                {avatarVersions ?
                  <SmartImage
                    aspectRatio={1}
                    maxWidth={isMobile ? 158 : 369}
                    versions={avatarVersions}
                    className={styles.image}
                  /> :
                  <div className={styles.altImage} />
                }
              </div>
            </div>
            <div className={styles.content}>
              <H4>
                <Link
                  type='primary'
                  to={`/authors/${slug}`}
                >
                  {name}
                </Link>
              </H4>
              <div className={styles.editorBody}>
                <Texts type='body' className={styles.jobTitle}>
                  {jobTitle}
                </Texts>
              </div>
            </div>
          </div>
        );
      })
      }
    </div>
  );
}

OurRedaction.propTypes = {
  isMobile: PropTypes.bool,
  authors: PropTypes.arrayOf(modelPropTypes(personAttributes)),
  theme: themePropTypes(`
    {
      texts: {
        bodySmall: {
          font
        },
      }
    }
  `),
  mediaUrlPrefix: PropTypes.string,
};

export default compose(
  withBreakpoint,
  withTheme,
)(OurRedaction);
