import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import SmartImage from 'core/components/SmartImage';

import Button from 'site/components/Button';
import Ogryzok from 'site/components/Ogryzok';
import GradientMask from 'site/components/GradientMask';

import { PVP_NAV } from 'site/constants';

import Info from './Info';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
  'authors',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'announce',
  'list_headline',
  'read_duration',
  'published_at',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardPvp1(props) {
  const {
    content,
    theme,
  } = props;

  const {
    link,
    headline,
    announce,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const FIRST_ROW_HEIGHT = '173rem';
  const SECOND_CELL_WIDTH = '474rem';
  const INDENT = '40rem';

  return (
    <Ogryzok
      className={styles.ogryzok}
      width={SECOND_CELL_WIDTH}
      height={FIRST_ROW_HEIGHT}
      padding='28rem 33rem 27rem'
      radius='60rem'
      background={theme.colors.white}
      position='top-right'
      content={(
        <Info content={content} />
      )}
    >
      <style jsx>{`
        .${styles.cardPvp1}
          background ${theme.colors.gray100}
          padding ${INDENT} 28rem 56rem ${INDENT}
          grid-template-columns 1fr calc(${SECOND_CELL_WIDTH} - ${INDENT})
          grid-template-rows ${FIRST_ROW_HEIGHT} 1fr
          
        .${styles.content}
          color ${theme.colors.primary}

          :global(h2)
          :global(h3)
          :global(h4)
            color ${theme.texts.title4.color}
            font ${theme.texts.title4.font}
      `}</style>
      <div
        className={styles.cardPvp1}
        id={`${PVP_NAV}-${content.id}`}
      >
        <Texts type='title4Medium' className={styles.headline}>
          <MarkdownWrapper children={headline} inline />
        </Texts>
        {versions.original && (
          <div className={styles.cover}>
            <GradientMask containerType='cardPvp1'>
              <SmartImage
                versions={versions}
                maxWidth={565}
                aspectRatio={1.25}
              />
            </GradientMask>
          </div>
        )}
        <div className={styles.content}>
          <div className={styles.announce}>
            <MarkdownWrapper children={announce} />
          </div>
          <Button
            link={link}
            size='large'
            className={styles.button}
            minWidth='100%'
          >
            Узнать всё
          </Button>
        </div>
      </div>
    </Ogryzok>
  );
}

CardPvp1.propTypes = {
  /** Данные для карточки, соответствующие модели `topicAttributes` */
  content: modelPropTypes(topicAttributes),

  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(CardPvp1);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export { CardPvp1 as StorybookComponent };
export default Card;
