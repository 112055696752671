import { Desktop, Mobile } from 'core/components/breakpoint';

import AdfoxDesktop from './desktop';
import AdfoxMobile from './mobile';


export default function Adfox() {
  return (
    <>
      <Desktop>
        <AdfoxDesktop />
      </Desktop>
      <Mobile>
        <AdfoxMobile />
      </Mobile>
    </>
  );
}
