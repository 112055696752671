import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import topicProvider from 'core/resolver/data/topic';
import resolveRelationships from 'core/utils/relationships';
import themePropTypes from 'core/utils/prop-types/theme';

import withTheme from 'core/components/theme';
import { Mobile } from 'core/components/breakpoint';
import withPageHocs from 'core/components/withPageHocs';

import Topic from 'core/components/Topic';
import { NegativeMobile, Indent } from 'core/components/Wrappers';

import TopicHeader, {
  requiredFields as topicHeaderRequiredFields,
  requiredImports as topicHeaderRequiredImports,
} from 'site/components/TopicHeader';
import TableOfContent from 'site/components/TableOfContent';

import { WIZARD_SLUG } from 'site/constants';

import Cover from './Cover';
import Content from './Content';
import TopicWizard from './TopicWizard';

import styles from './index.styl';

const relationships = resolveRelationships(['content', ...topicHeaderRequiredImports], null, {
  content: {
    widgets: [],
  },
});

function TopicPage({ content, theme }) {
  const { topicContent } = theme.controls;

  return (
    <Topic content={content} hideAmpLink>
      {topic => {
        const { topic_type: topicType } = topic.attributes;

        if (topicType === WIZARD_SLUG) {
          return <TopicWizard content={topic} />;
        }

        const {
          content: {
            widgets: contentWidgets,
          },
        } = relationships(topic);

        /**
         * Отображаем TopicHeader, только если в нем есть критические данные для рендера.
         */
        const hasTopicHeaderAttributes = topicHeaderRequiredFields.filter(name => !!topic.attributes[name]).length > 0;
        const hasTopicHeaderPlugin = topicHeaderRequiredImports.filter(name => !!relationships(topic)[name]).length > 0;
        const showTopicHeader = hasTopicHeaderAttributes || hasTopicHeaderPlugin;
        const needCollapse = showTopicHeader && contentWidgets && contentWidgets[0]?.type === 'markdown';

        return (
          <div className='topic'>
            <Helmet>
              <meta name='robots' content='noindex, nofollow' />
            </Helmet>
            <Cover content={topic} />
            <div className={styles.stickyContainer}>
              <Mobile>
                <TableOfContent content={topic} controlClassName={styles.control} />
              </Mobile>
              {showTopicHeader && (
                <NegativeMobile>
                  <TopicHeader content={topic} needCollapse={needCollapse} />
                  <Indent bottom={topicContent.widget.bottom} />
                </NegativeMobile>
              )}
              <Content
                content={topic}
                showTopicHeader={showTopicHeader}
              />
            </div>
          </div>
        );
      }}
    </Topic>
  );
}

TopicPage.propTypes = {
  content: PropTypes.object,
  theme: themePropTypes(`
    {
      controls: {
        topicContent: {
          widget: {
            bottom,
          }
        },
      },
    }
  `),
};

export default withPageHocs(topicProvider)(withTheme(TopicPage));
