import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import withTheme from 'core/components/theme';
import Texts from 'core/components/Texts';
import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';

import Button from 'site/components/Button';

import ArrowTopRight from 'site/icons/ArrowTopRight';

import styles from './index.styl';

const relationships = resolveRelationships(['image'], {});


function Answer(props) {
  const {
    answer,
    theme,
    isMobile,
  } = props;

  const {
    attributes: {
      list_headline: listHeadline,
      headline,
      link,
    },
  } = answer;

  const {
    image: {
      versions,
    },
  } = relationships(answer);

  return (
    <div className={styles.answer}>
      <style jsx>{`
        .${styles.answer}
          background ${theme.colors.gray100}
      `}</style>

      <SmartImage
        versions={versions}
        className={styles.image}
        maxWidth={isMobile ? 42 : 112}
        aspecrRatio={1}
        version='icon_3d'
        instant
      />

      <Texts type='title4' className={styles.title}>
        {listHeadline || headline}
      </Texts>

      <Link to={link} className={styles.link}>
        <Button>
          <ArrowTopRight />
        </Button>
      </Link>
    </div>
  );
}

Answer.propTypes = {
  answer: PropTypes.object,
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withTheme(Answer);
