import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.styl';

const Ellipse = ({ children, className }) => (
  <span className={cx(styles.ellipse, className)}>
    {children}
  </span>
);

Ellipse.propTypes = {
  className: PropTypes.string,
};

export default Ellipse;
