import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function TopicSection(props) {
  const {
    className,
    children,
    subContent,
    disableTopRadius,
    disableBottomRadius,
    collapseTop,
  } = props;

  return (
    <div
      className={cx(
        className,
        styles.topicSection,
        disableTopRadius && styles._disableTopRadius,
        disableBottomRadius && styles._disableBottomRadius,
        collapseTop && styles._collapseTop,
        subContent && styles._hasSubContent,
      )}
    >
      {subContent && <div className={styles.subContent}>{subContent}</div>}
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
}

TopicSection.propTypes = {
  className: PropTypes.string,
  /**
   * Вставка дополнительного компонента:<br/>
   * - desktop - взамен большого отступа
   * - mobile - вставка происходит перед основным контентом
   */
  subContent: PropTypes.node,
  /**
   * Отменяет скругление верхних углов
   */
  disableTopRadius: PropTypes.bool,

  /**
   * Отменяет скругление нижних углов
   */
  disableBottomRadius: PropTypes.bool,

  /**
   * Отменяет верхний padding
   */
  collapseTop: PropTypes.bool,
};

TopicSection.displayName = 'TopicSection';

export { TopicSection as StorybookComponent };
