import { POKUPKA_SLUG, VLADENIE_SLUG, PRODAGA_SLUG } from 'site/constants.js';

export default {
  [PRODAGA_SLUG]: {
    title: 'Хочу продать',
    checklistTitle: 'Готовимся к\u00A0продаже',
    labels: [
      'Подготовка',
      'Тюнинг',
      'Продажа',
    ],
  },
  [VLADENIE_SLUG]: {
    title: 'Есть авто',
    checklistTitle: 'Чек-лист автомобилиста',
    labels: [
      'Безопасность',
      'Уход',
      'Поездки',
    ],
  },
  [POKUPKA_SLUG]: {
    title: 'Хочу купить',
    checklistTitle: 'Важное перед покупкой авто',
    labels: [
      'Изучение',
      'Решение',
      'Покупка',
    ],
  },
};
