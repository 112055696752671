import PropTypes from 'prop-types';

import modelPropTypes, { photoGalleryAttributes } from 'core/utils/prop-types/model';

import Gallery from 'core/components/Gallery';

import GradientBackground from 'site/components/GradientBackground';


import styles from './index.styl';

export default function PhotoGallery({ block }) {
  return (
    <GradientBackground className={styles.gradient}>
      <div className={styles.photoGallery}>
        <Gallery
          photoGallery={block}
          withSmoothHeightChange
          arrowsShift={30}
          imageSizeAsContainer
        />
      </div>
    </GradientBackground>
  );
}

PhotoGallery.propTypes = {
  block: modelPropTypes(photoGalleryAttributes).isRequired,
  parentProps: PropTypes.object,
};
