import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import skip from 'core/resolver/skip';

import Page from 'core/components/Page';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import { NegativeMobile } from 'core/components/Wrappers';

import RubricHeader from 'site/components/RubricHeader';
import Section from 'site/components/Section';

import info from './texts/info';
import privacy from './texts/privacy';
import mailingRules from './texts/mailing-rules';

import styles from './index.styl';

function LegalPage(props) {
  const {
    match: {
      params: {
        level2,
      },
    },
  } = props;

  let title;
  let content;

  switch (level2) {
    case 'privacy':
      title = 'Политика конфиденциальности';
      content = privacy;
      break;
    case 'terms-of-use':
      title = 'Условия использования';
      content = info;
      break;
    case 'legal-info':
      title = 'Правовая информация';
      content = info;
      break;
    case 'mailing-rules':
      title = 'Правила рассылок';
      content = mailingRules;
      break;
    default:
      title = 'Правовые сведения';
      content = `[Политика конфиденциальности](/legal/privacy)\n
[Условия использования](/legal/terms-of-use)\n
[Правовая информация](/legal/legal-info)\n
[Правила рассылок](/legal/mailing-rules)
      `;
  }

  return (
    <Page title={title}>
      <RubricHeader title={title} />
      <NegativeMobile>
        <Section className={styles.section} withBackground >
          <MarkdownWrapper className={styles.content}>
            {content}
          </MarkdownWrapper>
        </Section>
      </NegativeMobile>
    </Page>
  );
}

LegalPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      level2: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default skip(withRouter(LegalPage));
