import PropTypes from 'prop-types';
import Section from 'site/components/Section';
import { withBreakpoint } from 'core/components/breakpoint';
import EmptyWrapper from 'core/components/EmptyWrapper';

import News from 'site/components/News';
import styles from './index.styl';

function NewsFeed({ content, isMobile }) {
  if (!content.length) return null;

  const Wrapper = isMobile ? EmptyWrapper : Section;

  return (
    <Wrapper
      className={styles.wrapper}
      withBackground
    >
      <News content={content} />
    </Wrapper>
  );
}

NewsFeed.propTypes = {
  content: PropTypes.array,
  isMobile: PropTypes.bool,
};

NewsFeed.displayName = 'NewsFeed';

export { NewsFeed as StorybookComponent };

export default withBreakpoint(NewsFeed);

