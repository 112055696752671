import { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import Swipe, { Slide } from 'core/components/Swipe';

import BlockNavigation from 'site/components/BlockNavigation';

import Card from './Card';
import styles from './index.styl';


export default function DesktopTestDrives({ content, activeIndex, onActiveChange }) {
  const swipeEl = useRef(null);

  const nextHandler = useCallback(() => swipeEl.current.next(), []);
  const prevHandler = useCallback(() => swipeEl.current.prev(), []);

  // const goToHandler = useCallback(index => {
  //   swipeEl.current.goto(index);
  // }, []);

  if (!content.length) return null;

  const paginationProps = {
    activeIndex,
    prevHandler,
    nextHandler,
    // goToHandler,
  };

  return (
    <>
      <Swipe
        loop
        ref={swipeEl}
        onActiveIndexChange={onActiveChange}
        containerClass={styles.swipe}
      >
        {content.map(topic => (
          <Slide
            width='100%'
            key={topic.id}
            additionalClass={styles.wrapperClass}
          >
            <Card content={topic} />
          </Slide>
        ))}
      </Swipe>
      <div className={styles.pagination}>
        <BlockNavigation topics={content} {...paginationProps} />
      </div>
    </>
  );
}

DesktopTestDrives.propTypes = {
  content: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
};
