import PropTypes from 'prop-types';

import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';

import withPageHocs from 'core/components/withPageHocs';
import { withBreakpoint } from 'core/components/breakpoint';
import bindPropsHOC from 'core/components/bindProps';
import { Indent } from 'core/components/Wrappers';
import { Helmet } from 'core/libs/helmet';

import Page from 'core/components/Page';

import RubricHeader from 'site/components/RubricHeader';
import SectionNews from 'site/components/SectionNews';
import Skeleton from 'site/components/SectionNews/Skeleton';
import NewsFeed from 'site/components/NewsFeed';
import Paginator from 'site/components/Paginator';

import CardHorizontal from 'site/cards/CardHorizontal';
import CardVertical from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';

import { TAG_PAGE_LIMIT } from 'site/constants';

const commonApiParams = {
  sort: '-published_at',
  include: filterRequiredParams([CardHorizontal, CardVertical, CardRect1], 'include'),
  fields: filterRequiredParams([CardHorizontal, CardVertical, CardRect1], 'fields'),
};

function TagPage(props) {
  const {
    tag,
    rawContent,
    loading,
    isMobile,
    match: { params: { tagSlug } },
  } = props;

  const {
    title,
    meta_title: metaTitle,
    meta_description: pageDescription,
  } = tag?.attributes || {};

  const {
    topics,
    count,
  } = rawContent || {};
  const indent = isMobile ? '38rem' : '80rem';

  const firstBlockTopics = topics?.slice(0, 9);
  const secondBlockTopics = topics?.slice(9, 18);
  const thirdBlockTopics = topics?.slice(18, 22);
  const pageTitle = metaTitle || title;

  return (
    <Page>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='description' content={pageDescription} />
      </Helmet>
      <RubricHeader title={title} />
      <Indent bottom={isMobile ? '16rem' : '80rem'} />
      {loading && <Skeleton />}
      {!loading && (
        <>
          {firstBlockTopics.length > 0 && (
            <Indent bottom={indent}>
              <SectionNews content={{ topics: firstBlockTopics }} />
            </Indent>
          )}
          {secondBlockTopics.length > 0 && (
            <Indent bottom={indent}>
              <SectionNews
                content={{ topics: secondBlockTopics }}
                columnReverse
                {...isMobile && { sectionTitle: 'Актуальное' }}
              />
            </Indent>
          )}
          {thirdBlockTopics.length > 0 && (
            <Indent bottom={indent}>
              <NewsFeed content={thirdBlockTopics} />
            </Indent>
          )}
          <Paginator
            apiParams={{
              tag: tagSlug,
              ...commonApiParams,
            }}
            startOffset={TAG_PAGE_LIMIT}
            filteredCount={count}
          />
          <Indent bottom={indent} />
        </>
      )}
    </Page>
  );
}

TagPage.propTypes = {
  tag: PropTypes.object,
  rawContent: PropTypes.object,
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      tagSlug: PropTypes.string,
    }),
  }),
};

const dataProvider = resolve({
  tag: (props) => {
    const {
      tag,
      bebopApi,
      renderError,
      match: { params: { tagSlug } },
    } = props;

    return tag || bebopApi
      .getTag({ tag_slug: tagSlug })
      .then(denormalizeData)
      .catch(renderError);
  },

  rawContent: (props) => {
    const {
      rawContent,
      bebopApi,
      consoleError,
      match: { params: { tagSlug } },
    } = props;

    return rawContent || bebopApi
      .getTopics({
        tag: tagSlug,
        limit: TAG_PAGE_LIMIT,
        with_filtered_count: 1,
        ...commonApiParams,
      })
      .then(data => ({
        topics: denormalizeData(data),
        count: data?.meta.filtered_count,
      }))
      .catch(consoleError('tag topics', { topics: [], count: 0 }));
  },
});

const Loader = bindPropsHOC({ loading: true })(TagPage);

export default withBreakpoint(withPageHocs(dataProvider, Loader)(TagPage));
