import cx from 'classnames';

import styles from './index.styl';


export default function Skeleton() {
  return (
    <div className={styles.news}>
      <div className={styles.left}>
        <div className={cx(styles.titleMob, 'lb-skeleton')} />
        <div className={cx(styles.mainCard, 'lb-skeleton')} />
        <div className={cx(styles.mainCard, 'lb-skeleton')} />
      </div>
      <div className={styles.right}>
        <div className={cx(styles.titleDesk, 'lb-skeleton')} />
        <div className={styles.topics}>
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
          <div className={cx(styles.topic, 'lb-skeleton')} />
        </div>
      </div>
    </div>
  );
}

