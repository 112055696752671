import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import Avatar from 'core/components/Avatar';

import styles from './index.styl';

export default function Author({ author, showAvatar, disableLink, shortName }) {
  if (!author) return null;

  const {
    slug,
    name,
    avatar,
  } = author.attributes;

  const formattedName = shortName
    ? name.trim().split(/\s+/).map((item, index) => index === 1 ? item[0] + '.' : item).join(' ')
    : name;

  const AuhtorRender = (
    <>
      {avatar && showAvatar && (
        <Avatar
          isCircular
          src={avatar?.attributes?.versions?.original}
          size={24}
        />
      )}
      {formattedName}
    </>
  );

  if (disableLink) return <div className={styles.author}>{AuhtorRender}</div>;

  return (
    <Link to={'/authors/' + slug} className={styles.author}>
      {AuhtorRender}
    </Link>
  );
}

Author.propTypes = {
  author: PropTypes.object,
  showAvatar: PropTypes.bool,
  showPrefix: PropTypes.bool,
  disableLink: PropTypes.bool,
  shortName: PropTypes.bool,
};
