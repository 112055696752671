import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Circle from './Circle.svg';

export const trafficColors = [
  { colorInner: '#0083EB', colorOuter: '#64B6FC' },
  { colorInner: '#FFAB35', colorOuter: '#FBF101' },
  { colorInner: '#00FF56', colorOuter: '#ABFFBA' },
];

export default function TrafficLight({ colorInner, colorOuter, className, ...otherProps }) {
  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .circle
          :global(.colorOuter)
            fill ${colorOuter}

          :global(.colorInner)
            fill ${colorInner}
      `}</style>
    </scope>
  );

  return (
    <>
      <Circle className={scope.wrapClassNames('circle', className)} {...otherProps} />
      <scope.styles />
    </>
  );
}

TrafficLight.propTypes = {
  colorInner: PropTypes.string,
  colorOuter: PropTypes.string,
  className: PropTypes.string,
};

TrafficLight.defaultProps = {
  colorInner: trafficColors[0].colorInner,
  colorOuter: trafficColors[0].colorOuter,
};
