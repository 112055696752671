import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Link from 'core/components/Link';
import Texts from 'core/components/Texts';

import styles from './index.styl';

const relationships = resolveRelationships(['rubric'], {});

function TopicBreadcrumbs({ content }) {
  const { headline } = content.attributes;
  const { rubric } = relationships(content);

  return (
    <Texts type='button' className={styles.topicBreadcrumbs}>
      <Link type='primary' to='/'>Главная</Link>
      /
      <Link
        type='primary'
        to={'/' + rubric.root_slug}
        className={styles.rubric}
      >
        {rubric.root_title}
      </Link>
      /
      <span className={styles.title}>
        {headline}
      </span>
    </Texts>
  );
}

TopicBreadcrumbs.propTypes = {
  content: PropTypes.object,
};

export default TopicBreadcrumbs;
