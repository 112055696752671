import PropTypes from 'prop-types';

import { dateFormat } from 'core/utils/dates';
import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import SmartImage from 'core/components/SmartImage';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';
import withTheme from 'core/components/theme';

import Button from 'site/components/Button';
import SberScore from 'site/components/SberScore';
import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


const relationships = resolveRelationships(['test_drive_meta', 'image', 'authors'], null, {
  test_drive_meta: {},
  image: {
    versions: {},
  },
  authors: [],
});

function Card({ content, theme }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
    published_at: publishedAt,
    announce,
  } = content.attributes || {};

  const {
    test_drive_meta: testDriveMeta,
    image: {
      versions,
    },
    authors,
  } = relationships(content) || {};

  const authorName = authors[0]?.attributes.name;
  const blockHeadline = listHeadline || headline;

  return (
    <div className={styles.card}>
      <style jsx>{`
        .${styles.card}
          font-family ${theme.fonts.display}
      `}</style>
      <div className={styles.header}>
        <Texts type='bodySmall' className={styles.number}>№{testDriveMeta.number}</Texts>
        <Texts type='title3' className={styles.headline}>{blockHeadline}</Texts>
      </div>
      <div className={styles.images}>
        <div className={styles.ovalImage}>
          <GradientMask className={styles.ovalGradient} hideEllipsis>
            <SmartImage
              versions={versions}
              maxWidth={705}
              version='horizontal'
              aspectRatio={2.11}
            />
          </GradientMask>
        </div>
        <div className={styles.squareCover}>
          <GradientMask className={styles.squareGradient} hideEllipsis>
            <SmartImage
              versions={versions}
              maxWidth={334}
              version='square'
              aspectRatio={1}
            />
          </GradientMask>
        </div>
      </div>
      <SberScore sberScore={testDriveMeta.sber_score} className={styles.score} />
      <div className={styles.content}>
        <div className={styles.announceWrapper}>
          <MarkdownWrapper
            className={styles.announce}
            interitemSpacing={24}
          >
            {announce}
          </MarkdownWrapper>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.info}>
            <span>
              Автор:
              <div>{authorName}</div>
            </span>
            <span className={styles.time}>
              {dateFormat(publishedAt, 'dd.MM.yyyy')}
            </span>
          </div>
          <Button
            link={link}
            size='large'
            minWidth='100%'
            className={styles.button}
          >
            Читать обзор
          </Button>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  content: modelPropTypes(topicAttributes),
  theme: PropTypes.object,
};

export default withTheme(Card);
