import PropTypes from 'prop-types';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import GridFeed from 'core/components/GridFeed';

import MobileDrum from 'site/components/MobileDrum';

import { CardHorizontalM } from 'site/cards/CardHorizontal';
import { CardVerticalS } from 'site/cards/CardVertical';
import CardRect1 from 'site/cards/CardRect1';


const desktopGridTemplate = `
  'a a a'
  'b c d'
`;

const mobileGridTemplate = `
  'a'
`;

const CardRect1Desktop = props => (
  <CardRect1
    {...props}
    aspectRatio={1.52}
    imageMaxWidth={526}
    cardHeight={580}
    showAltHeadline
  />
);


function ArticlesFeed(props) {
  const {
    isMobile,
    content,
    showAuthors,
    showPublishedAt,
  } = props;

  if (!content.length) return null;

  const CardHorizontalDesktop = params => (
    <CardHorizontalM
      {...params}
      showPublishedAt={showPublishedAt}
      showAuthors={showAuthors}
    />
  );

  const cards = isMobile
    ? CardVerticalS
    : [CardHorizontalDesktop, CardRect1Desktop];

  return (
    <>
      <GridFeed
        card={cards}
        content={isMobile ? content.slice(0, 1) : content}
        gap={isMobile ? '0' : '22rem 16rem'}
        {...isMobile ? {
          gridTemplateAreas: mobileGridTemplate,
        } : {
          gridTemplateAreas: desktopGridTemplate,
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridTemplateRows: content.length === 1 ? '1fr' : '1fr 580rem',
        }}
      />
      <Mobile><MobileDrum content={content.slice(1)} /></Mobile>
    </>
  );
}

ArticlesFeed.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Контент. */
  content: PropTypes.array,
  showAuthors: PropTypes.bool,
  showPublishedAt: PropTypes.bool,
};

const ArticlesFeedWithHOCs = withBreakpoint(ArticlesFeed);
ArticlesFeedWithHOCs.displayName = 'ArticlesFeed';

export default ArticlesFeedWithHOCs;

export { ArticlesFeed as StorybookComponent };
