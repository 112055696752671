import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Texts from 'core/components/Texts';

import TopicBreadcrumbs from 'site/components/TopicBreadcrumbs';
import Authors from 'site/components/Authors';
import Copyright from 'site/components/Copyright';
import ReadDuration from 'site/components/ReadDuration';
import PublishedDate from 'site/components/PublishedDate';
import TopicCover from 'site/components/TopicCover';

import styles from './index.styl';

const relationships = resolveRelationships(['authors', 'image'], {}, {
  image: {
    versions: {},
  },
});

function CoverDesktop({ content }) {
  const {
    headline,
    read_duration: readDuration,
    published_at: publishedAt,
  } = content.attributes;

  const {
    authors,
    image,
  } = relationships(content);

  return (
    <div className='coverDesktop'>
      <TopicBreadcrumbs content={content} />
      {/* вот такой вот заголовок, при этом h1 спрятан в хлебных крошках */}
      <Texts
        type='title3'
        tag='h1'
        className={styles.title}
      >
        {headline}
      </Texts>
      <Texts type='button' className={styles.info}>
        <PublishedDate publishedAt={publishedAt} />
        <ReadDuration readDuration={readDuration} />
        <Authors authors={authors} showPrefix />
        <Copyright content={content} className={styles.source} />
      </Texts>
      <TopicCover
        image={image}
        headline={headline}
      />
    </div>
  );
}

CoverDesktop.propTypes = {
  content: PropTypes.object,
};

export default CoverDesktop;
