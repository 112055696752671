import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import Link from 'core/components/Link';

import styles from './index.styl';


function Tags({ tags, theme }) {
  if (!tags?.length) return null;

  const novosti = tags.filter(tag => tag.attributes.tag_type === 'novosti');

  if (novosti.length === 0) return null;

  const { gray100 } = theme.colors;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.tag}
          background-color ${gray100}
          transition .25s linear
          
          &:hover
            background-color ${gray100}
            transition 0s
      `}</style>
    </scope>
  );

  return (
    <Texts
      type='buttonCTA'
      className={styles.tags}
      data-qa='tags'
    >
      {novosti.map(tag => {
        const { title, slug } = tag.attributes;

        return (
          <Link
            key={slug}
            type='primary'
            className={scope.wrapClassNames(styles.tag)}
            to={`/news/${slug}`}
          >
            {title}
          </Link>
        );
      })}
      <scope.styles />
    </Texts>
  );
}

Tags.propTypes = {
  tags: PropTypes.array,
  /** @ignore */
  theme: PropTypes.object,
};

const TagsWithHOCs = withTheme(Tags);
Tags.displayName = 'Tags';

export { Tags as StorybookComponent };

export default TagsWithHOCs;
