import PropTypes from 'prop-types';

import { Mobile, withBreakpoint } from 'core/components/breakpoint';

import TopicSection from 'site/components/TopicSection';
import TableOfContent from 'site/components/TableOfContent';
import { ControlShape } from 'site/components/TableOfContent/Control';

import { CAR_REVIEW_SLUG } from 'site/constants';

import Content, { requiredImports, requiredFields } from './Content';

function TopicHeader({ content, isDesktop, needCollapse }) {
  const topicType = content.attributes.topic_type;
  const isCarReview = topicType === CAR_REVIEW_SLUG;

  return (
    <TopicSection
      {...isDesktop && { subContent: <TableOfContent content={content} /> }}
      disableTopRadius
      disableBottomRadius={needCollapse}
    >
      {!isCarReview && (
        <Mobile>
          <ControlShape />
        </Mobile>
      )}
      <Content content={content} />
    </TopicSection>
  );
}

TopicHeader.propTypes = {
  content: PropTypes.object,
  isDesktop: PropTypes.bool,
  needCollapse: PropTypes.bool,
};

const TopicHeaderWithHOCs = withBreakpoint(TopicHeader);
TopicHeaderWithHOCs.displayName = 'TopicHeader';

export {
  TopicHeader as StorybookComponent,
  requiredImports,
  requiredFields,
};
export default TopicHeaderWithHOCs;
