import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { imagePropTypes } from 'core/utils/prop-types/relationships';
import getImageUrl from 'core/utils/smart-image';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import RubricHeader from 'site/components/RubricHeader';

import styles from './index.styl';


function AuthorBackground({ theme, image, title, isMobile }, { webdavHost, thumborHost }) {
  const originalImage = image && (image.versions || image.attributes?.versions).original;
  const imageUrl = originalImage && getImageUrl({
    src: originalImage,
    webdavHost,
    thumborHost,
    maxWidth: isMobile ? 750 : 1664,
  });

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.authorBackground}
          background-color ${theme.colors.gray800}
          background-image ${imageUrl ? `url('${imageUrl}')` : 'none'}
      `}</style>
    </scope>
  );

  return (
    <>
      <RubricHeader
        title={title}
        showGradientMask
        headlineSize='smaller'
        className={scope.wrapClassNames(styles.authorBackground)}
      />
      <scope.styles />
    </>
  );
}

AuthorBackground.propTypes = {
  isMobile: PropTypes.bool,
  title: PropTypes.string,
  image: imagePropTypes(),
  /** @ignore */
  theme: PropTypes.object,
};

AuthorBackground.contextTypes = {
  webdavHost: PropTypes.string,
  thumborHost: PropTypes.string,
};

export default withTheme(withBreakpoint(AuthorBackground));
