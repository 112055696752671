import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import styles from './index.styl';


function CarPrice(props) {
  const {
    theme,
    carReviewMeta: {
      price_from: priceFrom,
      price_to: priceTo,
    },
  } = props;

  if (!priceFrom && !priceTo) return null;

  return (
    <div className={styles.range}>
      <style jsx>{`
      .${styles.range}
        &:before
          background ${theme.colors.green400}
        &:after
          font-family ${theme.fonts.display}

      .${styles.rangeItem}
        font-family ${theme.fonts.text}
        &:after
          background ${theme.colors.green200}
          border-color ${theme.colors.green400}

        &:before
          background ${theme.colors.primary}
    `}</style>
      <span className={styles.rangeItem}>{new Intl.NumberFormat('ru-RU').format(priceFrom)} ₽</span>
      <span className={styles.rangeItem}>{new Intl.NumberFormat('ru-RU').format(priceTo)} ₽</span>
    </div>
  );
}

CarPrice.propTypes = {
  theme: PropTypes.object,
  carReviewMeta: PropTypes.object,
};

export default withTheme(CarPrice);
