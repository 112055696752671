import PropTypes from 'prop-types';

import Author from './Author';

import styles from './index.styl';

export default function Authors({ showPrefix, authors, showAvatar, showOnlyFirst, disableLink, shortName }) {
  if (!authors || authors.length === 0) return null;

  const authorNamesPrefix = (authors.length > 1 ? 'Авторы' : 'Автор');
  const prefix = showPrefix && authorNamesPrefix + ': ';

  if (showOnlyFirst) {
    return (
      <div className={styles.authors}>
        {prefix}
        <Author
          shortName={shortName}
          disableLink={disableLink}
          author={authors[0]}
          showAvatar={showAvatar}
        />
      </div>
    );
  }

  return (
    <div className={styles.authors}>
      {prefix}
      {authors.map((author, index) => {
        return (
          <div className={styles.item} key={author.id}>
            <Author
              shortName={shortName}
              disableLink={disableLink}
              author={author}
              showAvatar={showAvatar}
            />
            {authors[index + 1] && ', '}
          </div>
        );
      })}
    </div>
  );
}

Authors.propTypes = {
  authors: PropTypes.array,
  showAvatar: PropTypes.bool,
  showPrefix: PropTypes.bool,
  showOnlyFirst: PropTypes.bool,
  disableLink: PropTypes.bool,
  shortName: PropTypes.bool,
};
