import palette from './palette';

const colors = {
  primary: palette.black,
  layout: palette.white,
  content: palette.white,
  active1: palette.blue700,
  accent: palette.aqua200,
  hint: palette.black,
  divider: palette.gray100,
  aqua200: palette.aqua200,
  blue500: palette.blue500,
  blue700: palette.blue700,
  blue900: palette.blue900,
  black: palette.black,
  white: palette.white,
  green350: palette.green350,
  green300: palette.green300,
  green400: palette.green400,
  green600: palette.green600,
  gray100: palette.gray100,
  gray300: palette.gray300,
  gray500: palette.gray500,
  gray800: palette.gray800,
  ghost200: palette.ghost200,
  orange100: palette.orange100,
  boxShadowActive: palette.boxShadowActive,
  green200: palette.green200,
};

const texts = {
  title4: {
    color: colors.primary,
  },
  title4Medium: {
    color: colors.primary,
  },
  accent: {
    color: colors.primary,
  },
  quote: {
    color: colors.primary,
  },
  button: {
    color: colors.primary,
  },
  buttonCTA: {
    color: colors.primary,
  },
};

const button = {
  secondary: {
    idle: {
      color: colors.black,
      background: colors.white,
      border: 'none',
    },

    hover: {
      color: colors.white,
      background: colors.blue700,
      border: 'none',
    },

    active: {
      color: colors.white,
      background: colors.blue900,
      border: 'none',
      boxShadow: colors.boxShadowActive,
    },

    disabled: {
      color: colors.gray800,
      background: colors.gray500,
      border: 'none',
    },
  },
};

const link = {
  primary: {
    idle: {
      color: colors.primary,
      decoration: 'none',
    },

    hover: {
      color: colors.active1,
      decoration: 'none',
    },

    active: {
      color: colors.active1,
      decoration: 'none',
    },

    visited: {
      color: colors.primary,
      decoration: 'none',
    },
  },

  article: {
    idle: {
      color: colors.blue700,
      decoration: 'underline',
    },

    hover: {
      color: colors.primary,
      decoration: 'none',
    },

    active: {
      color: colors.primary,
      decoration: 'none',
    },

    visited: {
      color: colors.blue700,
      decoration: 'underline',
    },
  },
};

const gallery = {
  fullscreen: {
    background: colors.black,

    imageInfo: {
      background: colors.black,
      faderTransparency: colors.black,
    },
  },
};

export default {
  colors,
  texts,
  controls: {
    link,
    button,
    gallery,
  },
};
