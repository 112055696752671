import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import bindPropsHOC from 'core/components/bindProps';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import EmptyWrapper from 'core/components/EmptyWrapper';
import SectionHeader from 'site/components/SectionHeader';
import Adfox from 'site/components/Ads';
import ScrollerVertical from 'site/components/ScrollerVertical';

import CardRect2 from 'site/cards/CardRect2';
import CardHorizontal from 'site/cards/CardHorizontal';
import { CardVerticalS } from 'site/cards/CardVertical';

import styles from './index.styl';


function SectionNews(props) {
  const {
    content: {
      topics,
      count,
    },
    isMobile,
    sectionTitle,
    columnReverse,
    link,
    promoMobileIndex,
  } = props;

  if (topics?.length === 0) return null;

  const first = topics.slice(0, 1)[0];
  const rest = topics.slice(1);

  const HeroCard = isMobile ? CardVerticalS : bindPropsHOC({ imageRatio: 1.616 })(CardRect2);
  const ScrollerOrEmpty = isMobile ? EmptyWrapper : ScrollerVertical;
  return (
    <div className={cx(styles.sectionNews, columnReverse && styles._columnReverse)}>
      <div className={styles.left}>
        {sectionTitle && (
          <Mobile>
            <div className={styles.sectionTitle}>
              <SectionHeader count={count} link={link}>
                {sectionTitle}
              </SectionHeader>
            </div>
          </Mobile>
        )}
        {first && <HeroCard content={first} />}
        <Desktop><Adfox /></Desktop>
      </div>
      <div className={styles.right}>
        {sectionTitle && (
          <Desktop>
            <SectionHeader count={count} link={link}>{sectionTitle}</SectionHeader>
          </Desktop>
        )}
        {rest?.length > 0 && (
          <ScrollerOrEmpty>
            <div className={styles.rest}>
              {rest.map((topic, index) => (
                <Fragment key={topic.id}>
                  <Mobile>{index + 1 === promoMobileIndex && <Adfox />}</Mobile>
                  <CardHorizontal
                    content={topic}
                    imageMaxWidth={isMobile ? 158 : 265}
                    imageRatio={isMobile ? 1.64 : 1.59}
                    titleType={'title4'}
                    showPublishedAt
                    hideAltHeadline
                    dateWithOpacity
                    padding='0'
                    lineClamp={isMobile ? 3 : 2}
                    borderRadius={isMobile ? '16rem' : '28rem'}
                  />
                </Fragment>
              ))}
            </div>
          </ScrollerOrEmpty>
        )}
      </div>
    </div>
  );
}

SectionNews.propTypes = {
  isMobile: PropTypes.bool,
  content: PropTypes.shape({
    topics: PropTypes.arrayOf(modelPropTypes(topicAttributes)),
    count: PropTypes.number,
  }),
  sectionTitle: PropTypes.string,
  columnReverse: PropTypes.bool,
  link: PropTypes.string,
  promoMobileIndex: PropTypes.number,
};

SectionNews.defaultProps = {
  promoMobileIndex: 1,
};

SectionNews.displayName = 'SectionNews';

export { SectionNews as StorybookComponent };

export default withBreakpoint(SectionNews);
