import PropTypes from 'prop-types';

import pluralize from 'core/utils/pluralize';

import H2 from 'core/components/H2';

import styles from './index.styl';


export default function FoundBlock({ total }) {
  const suffix = pluralize(total, ['материал', 'материала', 'материалов']);

  return (
    <div className={styles.total}>
      <H2>{total} {suffix}</H2>
    </div>
  );
}

FoundBlock.propTypes = {
  total: PropTypes.number,
};
