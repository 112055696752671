import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import Texts from 'core/components/Texts';

import MajorScreen from 'site/components/MajorScreen';
import Ellipse from 'site/components/Ellipse';

import PVPMenu from 'site/components/PVPMenu';

import styles from './index.styl';

/**
 * Покупка - Владение - Продажа
 */
function PVP(props) {
  const {
    loading,
    isMobile,
  } = props;

  return (
    <MajorScreen type='mainCover' className={styles.majorScreen}>
      <Texts
        type='title1'
        tag='h1'
        className={styles.title}
      >
        Журнал{isMobile && <br />} про <Ellipse className={styles.pvpEllipse}>человека</Ellipse><br /> за рулём
      </Texts>
      <PVPMenu loading={loading} />
    </MajorScreen>
  );
}

PVP.propTypes = {
  loading: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(PVP);
