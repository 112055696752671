export default `
## Вступили в силу 24 сентября 2024 года.
<br />
Настоящие правила рассылок (Правила) определяют общие условия рассылки Сообщений Пользователям, осуществляемой Провайдером и/или Партнерами.


1. ТЕРМИНЫ

1.1	Провайдер – юридическое лицо, предоставляющее Пользователям Ресурс. Наименование Провайдера размещено на Ресурсе, на котором Пользователь совершает Подписку на рассылку.

1.2	Ресурс – сайт, проект, сервис и/или иной продукт в сети Интернет, предоставляемый Провайдером.

1.3	Пользователь – физическое лицо, использующее Ресурс и обладающее необходимой дееспособностью для принятия Правил.

1.4	Контент – дизайн, графика, изображения, фотографии, тексты, статьи, видео, музыка, песни, компьютерные программы, базы данных, товарные знаки, логотипы, любые иные результаты интеллектуальной деятельности и средства индивидуализации, размещенные или размещаемые на Ресурсе.

1.5	Документы – совместно Условия, политика конфиденциальности и иные документы, размещенные в соответствующем разделе Ресурса.


2.	ДЕЙСТВИЕ УСЛОВИЙ

2.1.Условия заключаются в соответствии с нормами законодательства РФ, регулирующими принятие публичной оферты конклюдентными действиями.

2.2	Условия считаются заключенными с момента их принятия Пользователем. Осуществление Пользователем доступа к Ресурсу означает, что Пользователь полностью и безоговорочно принял Условия. Условия обязательны для исполнения Пользователем и Провайдером с момента их заключения. Если Пользователь не согласен с Условиями, он не вправе использовать Ресурс.

2.3	Условия могут быть изменены Провайдером в одностороннем внесудебном порядке. Анонсирование изменений и публикация измененных Условий производится на Ресурсе. Используя Ресурс после изменения Условий, Пользователь принимает Условия полностью и безоговорочно в измененном виде.

2.4.	Ресурсом может быть предусмотрено предоставление Пользователю дополнительного функционала, правила использования которого регулируются в соответствии с отдельными документами.

3.	ИСПОЛЬЗОВАНИЕ РЕСУРСА

3.1	Предоставляя Ресурс, Провайдер ожидает от Пользователей, что они будут соблюдать права и законные интересы других Пользователей и иных третьих лиц, а также законодательство РФ. Более того, это является необходимым и обязательным условием использования Ресурса.

3.2	Пользователь осознает и соглашается, что Ресурс может содержать рекламу. При этом Пользователь не вправе размещать на Ресурсе рекламу и ссылки на рекламу.

3.3	Ресурс предназначен для личного некоммерческого использования, не связанного с осуществлением предпринимательской деятельности.

3.4	Пользователь самостоятельно несет ответственность за все действия, совершаемые им на Ресурсе, за нарушение законодательства РФ, Условий и иных Документов.

3.5	Ресурс предоставляется «как есть». Провайдер не гарантирует, что Ресурс и результат его использования будут удовлетворять ожиданиям Пользователя. Пользователь принимает все последствия и самостоятельно несёт все возможные риски, связанные с использованием Ресурса.

3.6	Провайдер не несет ответственности за любые виды убытков, произошедшие вследствие использования Ресурса и/или его отдельных функций.

3.7	Провайдер предоставляет Пользователю возможность получать в связи или в результате использования Ресурса сообщения информационного, новостного, рекламного или иного характера от Провайдера и/или ее партнеров. Условия предоставления таких сообщений регулируются Правилами рассылок.

3.8	В целях улучшения качества и удобства использования Ресурса, повышения его эффективности и разработки новых продуктов, а также проведения статистических и иных исследований на Ресурсе используются cookies (небольшие фрагменты данных о прошлых посещениях). Пользователь может просмотреть срок действия cookies, а также запретить их сохранение в настройках своего браузера.

4.	ИНОЕ
4.1	Отношения Провайдера и Пользователя, в том числе предусмотренные Условиями, регулируются законодательством РФ.

4.2	Все споры, возникающие из Условий и/или связанные с Ресурсом и Контентом, могут быть переданы на разрешение суда после принятия Сторонами мер по досудебному урегулированию спора по истечении 30 (тридцати) календарных дней со дня направления первой претензии. Если спор не урегулирован в досудебном порядке согласно настоящему пункту, он может быть передан на разрешение суда по месту нахождения Провайдера.

4.3	Юридически значимые сообщения в адрес Провайдера направляются по адресу:
117105, г. Москва, Варшавское ш., д. 9, стр. 1.

4.4	По настоящим Условиям Провайдер предоставляет Ресурс безвозмездно, поэтому нормы о защите прав потребителей не применяются.
`;
