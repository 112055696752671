import PropTypes from 'prop-types';

import { NegativeMobile } from 'core/components/Wrappers';
import Drum from 'core/components/Drum';
import ThemeSwitch from 'core/components/ThemeSwitch';

import CardForDrumAnimation from 'site/cards/CardForDrumAnimation';

import styles from './index.styl';


function MobileDrum({ content }) {
  return (
    <NegativeMobile>
      <ThemeSwitch name='dark'>
        <Drum
          containerClass={styles.container}
          content={content}
          card={CardForDrumAnimation}
          itemWidthMobile='72%'
          spaceBetween={12}
          noCounter
          autoHeight
        />
      </ThemeSwitch>
    </NegativeMobile>
  );
}

MobileDrum.propTypes = {
  /** Контент. */
  content: PropTypes.array,
};

MobileDrum.displayName = 'MobileDrum';

export { MobileDrum as StorybookComponent };

export default MobileDrum;
