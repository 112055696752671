export const firstMenu = [
  {
    title: 'Новости',
    url: '/news',
  },
  {
    title: 'Подборки',
    url: '/selection',
  },
  {
    title: 'Сравнения',
    url: '/comparison',
  },
  {
    title: 'Гайды',
    url: '/guide',
  },
  {
    title: 'Тест-драйв',
    url: '/testdrive',
  },
  {
    title: 'Обзоры',
    url: '/review',
  },
  {
    title: 'Интервью',
    url: '/interview',
  },
];

export const secondMenu = [
  {
    title: 'О нас',
    url: '/about',
  },
  {
    title: 'Редакция',
    url: '/about#our-experts',
  },
];

export const thirdMenu = [
  {
    title: 'Правовая информация',
    url: '/legal/legal-info',
  },
  {
    title: 'Условия использования',
    url: '/legal/terms-of-use',
  },
  {
    title: 'Политика конфиденциальности',
    url: '/legal/privacy',
  },
  {
    title: 'Правила рассылок',
    url: '/legal/mailing-rules',
  },
];
