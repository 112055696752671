import PropTypes from 'prop-types';

import Portal from 'core/components/Portal';

import Control from '../Control';

import styles from './index.styl';

export default function Modal({ children, opened, handleModal }) {
  if (!opened) return null;

  return (
    <Portal dataQa='portal'>
      <div className={styles.modal}>
        <Control handleModal={handleModal} controlClassName={styles.control} />
        {children}
      </div>
    </Portal>
  );
}

Modal.propTypes = {
  opened: PropTypes.bool,
  handleModal: PropTypes.func.isRequired,
};
