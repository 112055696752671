import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import { withBreakpoint } from 'core/components/breakpoint';
import themeSwitcher from 'core/components/themeSwitcher';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Texts from 'core/components/Texts';
import Link from 'core/components/Link';

import getImageUrl from 'core/utils/smart-image';

import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';


function Authors({ authors, isMobile, visibleAuthor, setVisibleAuthor }, { webdavHost, thumborHost }) {
  return authors.map((author, i) => {
    const {
      attributes: {
        avatar,
        name,
        job_title: jobTitle,
        slug,
      },
    } = author;

    if (!slug) return null;

    const avatarImage = get(avatar, 'attributes.versions.original');

    const imageUrl = avatarImage && getImageUrl({
      src: avatarImage,
      webdavHost,
      thumborHost,
      maxWidth: 600,
    });

    const handleClick = () => {
      setVisibleAuthor(visibleAuthor === i ? null : i);
    };

    const DesktopLink = isMobile ? 'div' : Link;
    const MobileLink = isMobile ? Link : 'div';

    const linkProps = {
      type: 'primary',
      to: `/authors/${slug}`,
    };

    const scope = resolveScopedStyles(
      <scope>
        <style jsx>{`
          .${styles.author}
            transition scale 500ms

          .${styles.avatar}
            background-image ${imageUrl ? `url('${imageUrl}')` : 'none'}
        `}</style>
      </scope>
    );

    return (
      <DesktopLink
        key={i}
        className={scope.wrapClassNames(
          styles.author,
          styles['_author' + (i + 1)],
          visibleAuthor === i && styles._visible,
        )}
        {...isMobile ? { onClick: handleClick } : linkProps}
      >
        <GradientMask className={scope.wrapClassNames(styles.avatar)} />
        <MobileLink className={styles.content} {...linkProps}>
          <Texts
            type={isMobile ? 'title4' : 'title5'}
            className={styles.name}
          >
            {name}
          </Texts>
          <Texts type={isMobile ? 'bodySmall' : 'body2'}>{jobTitle}</Texts>
        </MobileLink>
        <scope.styles />
      </DesktopLink>
    );
  });
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
  authors: PropTypes.array,
  visibleAuthor: PropTypes.number,
  setVisibleAuthor: PropTypes.func,
};

Authors.contextTypes = {
  webdavHost: PropTypes.string,
  thumborHost: PropTypes.string,
};

export default themeSwitcher('dark')(withBreakpoint(Authors));
