import PropTypes from 'prop-types';
import cx from 'classnames';

import ButtonFromLb from 'core/components/Button';
import Link from 'core/components/Link';

import styles from './index.styl';


export default function Button(props) {
  const {
    link,
    type,
    children,
    className,
    buttonClassName,
    size,
    loading,
    ...restProps
  } = props;

  if (!children) return null;

  const Wrapper = link ? Link : 'div';

  return (
    <Wrapper
      to={link}
      className={cx(
        styles.container,
        type === 'primary' && styles._primary,
        loading && styles.loading,
        className,
      )}
      type='blank'
    >
      <ButtonFromLb
        type={type}
        className={cx(
          styles.button,
          buttonClassName,
        )}
        size={size}
        {...restProps}
      >
        {children}
      </ButtonFromLb>
    </Wrapper>
  );
}

Button.propTypes = {
  link: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  type: 'primary',
  size: 'medium',
};

Button.displayName = 'Button';

export { Button as StorybookComponent };
