import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';

import CoreReactions from 'core/components/Reactions';

import styles from './index.styl';

function Reactions(props) {
  const {
    targetId,
    isMobile,
    themeType,
    labelClassName,
    reactionsClassName,
  } = props;

  return (
    <CoreReactions
      emojis={[
        'fire',
        'smiling_face_with_heart_eyes',
        'slightly_frowning_face',
        'clown',
      ]}
      targetId={targetId}
      emojiSize={isMobile ? 20 : 30}
      reactionsClassName={cx(styles.reactions, reactionsClassName)}
      labelClassName={cx(styles.label, labelClassName)}
      inputClassName={styles.input}
      contentClassName={styles.content}
      type={themeType}
    />
  );
}

Reactions.propTypes = {
  /**
   * ID топика для привязки реакций к конкретному топику
   */
  targetId: PropTypes.string,
  isMobile: PropTypes.bool,
  /**
   * Тип реакций в теме
   */
  themeType: PropTypes.string,
  labelClassName: PropTypes.string,
  reactionsClassName: PropTypes.string,
};

export default withBreakpoint(Reactions);

