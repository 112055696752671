import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import Texts from 'core/components/Texts';
import Button from 'site/components/Button';

import TravelMeta from 'site/components/TravelMeta';
import Reactions from 'site/components/Reactions';

import Slider from './Slider';
import styles from './index.styl';


const relationships = resolveRelationships(['travel_meta', 'image'], null, {
  image: {
    versions: {},
  },
});

export default function DesktopTravels(props) {
  const {
    topics,
    activeIndex,
    onActiveChange,
  } = props;

  const content = topics[activeIndex];

  const {
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
    link,
  } = content?.attributes;

  const {
    travel_meta: travelMeta,
  } = relationships(content);

  const blockHeadline = listHeadline || headline;

  return (
    <div className={styles.content}>
      <Slider
        versionsList={topics?.map(topic => relationships(topic).image.versions)}
        totalCount={topics?.length}
        activeIndex={activeIndex}
        onActiveChange={onActiveChange}
      />
      <div className={styles.text}>
        <div className={styles.leftBlock}>
          <TravelMeta travelMeta={travelMeta} />
          <Reactions
            key={content.id}
            targetId={content.id}
            reactionsClassName={styles.reactions}
            labelClassName={styles.reactionsLabel}
          />
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.headlines}>
            <Texts className={styles.headline} type='title3'>{blockHeadline}</Texts>
            <Texts className={styles.altHeadline} type='body'>{altHeadline}</Texts>
          </div>
          <Button
            className={styles.button}
            link={link}
            minWidth='100%'
            size='large'
          >
            Читать обзор
          </Button>
        </div>
      </div>
    </div>
  );
}

DesktopTravels.propTypes = {
  topics: PropTypes.array,
  activeIndex: PropTypes.number,
  onActiveChange: PropTypes.func,
};
