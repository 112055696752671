import deepmerge from 'core/libs/deepmerge';

import fonts from './fonts';

import { SIDE_INDENT_MOBILE } from 'site/constants';

export default function baseMobileTheme(atoms) {
  const layout = {
    maxWidth: '1280px',
    boxShadow: 'none',
    indents: {
      top: 0,
      right: SIDE_INDENT_MOBILE,
      bottom: 48,
      left: SIDE_INDENT_MOBILE,
    },
  };

  const texts = {
    title1: {
      font: `700 40rem/36rem ${fonts.display}`,
      letterSpacing: '0',
    },

    get headline() {
      return this.title1;
    },

    title2: {
      font: `600 20rem/20rem ${fonts.display}`,
    },

    title3: {
      font: `700 28rem/27rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    title4: {
      font: `600 16rem/16rem ${fonts.display}`,
    },

    button: {
      font: `400 12rem/12rem ${fonts.display}`,
    },

    buttonCTA: {
      font: `600 16rem/16rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    buttonMenu: {
      font: `400 16rem/16rem ${fonts.display}`,
    },

    accent: {
      font: `700 20rem/1.2 ${fonts.display}`,
      letterSpacing: '0.01em',
    },

    quote: {
      font: `700 20rem/24rem ${fonts.display}`,
    },

    body: {
      font: `400 16rem/19rem ${fonts.text}`,
      letterSpacing: '0.01em',
    },

    bodySmall: {
      font: `400 12rem/12rem ${fonts.display}`,
      letterSpacing: '0.01em',
    },
  };

  const sizes = {
    small: {
      font: texts.bodySmall.font,
      width: '60rem',
      height: '28rem',
      padding: '6rem',
    },
    medium: {
      font: texts.button.font,
      width: '54rem',
      height: '32rem',
      padding: '4rem 8rem',
    },
    large: {
      font: texts.buttonCTA.font,
      height: '40rem',
      padding: '12rem 18rem',
    },
    extraLarge: {
      font: texts.button.font,
      height: '48rem',
      padding: '12rem 18rem',
    },
  };

  const button = {
    const: {
      sizes,
    },

    primary: {
      hover: {
        color: atoms.colors.white,
      },
    },
  };

  const input = {
    const: {
      sizes: {
        ...sizes,
        medium: {
          ...sizes.medium,
          padding: '10rem 20rem',
        },
      },
    },
  };

  const topicContent = {
    widgetsWithNegativeMobileIndent: {
      'image': true,
      'markdown': true,
      'tableMarkdown': true,
      'incut': true,
      'opinion': true,
      'snippet': true,
      'readMore': true,
    },
    widget: {
      top: -12,
      bottom: -12,
    },
    image: {
      bottom: 0,
    },
  };

  const vikontReadMore = {
    block: {
      padding: 0,
    },
    drum: {
      arrowsShift: 0,
      arrowsShiftVertical: 0,
      itemWidth: '77%',
      spaceBetween: 16,
      sideIndent: 0,
      noCounter: true,
      autoHeight: true,
    },
  };

  const reactions = {
    small: {
      font: texts.button.font,
      radius: '32rem',
      padding: '4rem 8rem',
    },
  };

  /**
   * paginatron на странице поиска
   */
  const paginatron = {
    btnSize: 'extraSmall',
  };

  const captionCredits = {
    caption: {
      font: `600 20rem/1 ${fonts.text}`,
    },
    credits: {
      font: `400 16rem/1 ${fonts.text}`,
      margin: '8rem 0 0',
    },
  };

  return deepmerge({
    layout,
    texts,
    controls: {
      button,
      input,
      topicContent,
      vikontReadMore,
      reactions,
      paginatron,
      captionCredits,
    },
  }, atoms);
}
