import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';
import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/components/theme';

import SmartImage from 'core/components/SmartImage';
import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import GradientMask from 'site/components/GradientMask';

import { imageMaxWidthValidator } from 'site/utils/prop-types';

import styles from './index.styl';


const requiredPayloadImports = [
  'image',
];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, null, {
  image: {
    versions: {},
  },
});

function CardForDrumAnimation(props) {
  const {
    content,
    imageMaxWidth,
    imageRatio,
    theme,
  } = props;

  const {
    link,
    headline,
    list_headline: listHeadline,
    alternative_headline: altHeadline,
  } = content.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content) || {};

  const {
    colors: {
      black,
      green350,
      gray100,
      blue700,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.cardForDrumAnimation}
          max-width ${imageMaxWidth}px
          background-image linear-gradient(90deg, ${green350} -42%, ${blue700} 100%)

        .${styles.headline}
          :global(.swiper-slide-prev:not(.swiper-slide-fully-visible)) &
          :global(.swiper-slide-next:not(.swiper-slide-fully-visible)) &
            color ${black}
            background-color ${gray100}
      `}</style>
    </scope>
  );

  const title = listHeadline || headline;

  return (
    <Link
      to={link}
      title={title}
      type='primary'
      className={scope.wrapClassNames(styles.cardForDrumAnimation)}
    >
      {versions.original && (
        <div className={styles.cover}>
          <GradientMask hideEllipsis>
            <SmartImage
              className={styles.image}
              versions={versions}
              maxWidth={imageMaxWidth}
              aspectRatio={imageRatio}
            />
          </GradientMask>
        </div>
      )}
      <Texts type='title4' className={scope.wrapClassNames(styles.headline)}>
        <span className={styles.lineClamp}>
          <MarkdownWrapper children={title} inline />
        </span>
      </Texts>
      <Texts type='body' className={styles.altHeadline}>
        <span className={styles.lineClamp}>
          <MarkdownWrapper children={altHeadline} inline />
        </span>
      </Texts>
      <scope.styles />
    </Link>
  );
}

CardForDrumAnimation.propTypes = {
  /** Данные карточки */
  content: modelPropTypes(topicAttributes),

  /** Устанавливает максимальную ширину изображения */
  imageMaxWidth: imageMaxWidthValidator,

  /** Устанавливает соотношение сторон изображения */
  imageRatio: PropTypes.number,

  /** @ignore */
  theme: PropTypes.object,
};

CardForDrumAnimation.defaultProps = {
  imageRatio: 1.76,
  imageMaxWidth: 535,
};

const Card = withTheme(CardForDrumAnimation);

Card.requiredPayloadFields = requiredPayloadFields;
Card.requiredPayloadImports = requiredPayloadImports;

export default Card;
