import cx from 'classnames';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import Texts from 'core/components/Texts';

import { travelMetaAttributes } from 'site/utils/prop-types/common';

import styles from './index.styl';

/**
 * Сложность (от 0 до 10)
 */
const MAX_SCORE = 10;

function TravelMeta({ travelMeta, className, isTopicPage, isDesktop }) {
  if (!travelMeta) return null;

  const {
    country,
    location,
    difficulty,
  } = travelMeta;

  const placeMeta = { title: 'Место', value: location };
  const difficultyMeta = { title: 'Сложность', value: difficulty + ' / ' + MAX_SCORE };

  const renderData = [
    { title: 'Страна', value: country },
    ...isTopicPage && isDesktop
      ? [difficultyMeta, placeMeta]
      : [placeMeta, difficultyMeta],
  ];

  const valueTextType = isDesktop ? 'body' : 'bodySmall';

  return (
    <div className={cx(styles.travelMeta, className)}>
      {renderData.map(({ title, value }) => (
        <div key={title + ' ' + value}>
          <Texts type='title4'>{title}:</Texts>
          <Texts className={styles.value} type={valueTextType}>{value}</Texts>
        </div>
      ))}
    </div>
  );
}

TravelMeta.propTypes = {
  travelMeta: travelMetaAttributes,
  className: PropTypes.string,
  isTopicPage: PropTypes.bool,
  /** @ignore */
  isDesktop: PropTypes.bool,
};

const TravelMetaWithHOCs = withBreakpoint(TravelMeta);

TravelMetaWithHOCs.displayName = 'TravelMeta';

export { TravelMeta as StorybookComponent };
export default TravelMetaWithHOCs;
