import { Component } from 'react';
import PropTypes from 'prop-types';

import SmartImage from 'core/components/SmartImage';
import Swipe, { Slide } from 'core/components/Swipe';

import SliderArrows from 'site/components/SliderArrows';
import GradientMask from 'site/components/GradientMask';

import styles from './index.styl';

import Bullets from './Bullets';


class Slider extends Component {
  static propTypes = {
    versionsList: PropTypes.array,
    totalCount: PropTypes.number,
    activeIndex: PropTypes.number,
    onActiveChange: PropTypes.func,
  };

  goToHandler = index => this.heroSwipe.goto(index);
  prevHandler = () => this.heroSwipe.prev();
  nextHandler = () => this.heroSwipe.next();

  render() {
    const {
      versionsList,
      totalCount,
      activeIndex,
      onActiveChange,
    } = this.props;

    const slides = versionsList.map((versions, position) => {
      if (!versions) return null;

      return (
        <Slide key={position} width='100%'>
          <div className={styles.imageInnerWrapper}>
            <GradientMask hideEllipsis>
              <SmartImage versions={versions} aspectRatio={1576 / 577} />
            </GradientMask>
          </div>
        </Slide>
      );
    });

    /**
     * TODO: Если останется время потом, то следует локальный
     * свайпер сделать с EffectFade, красивее будет.
     */
    return (
      <div className={styles.slider}>
        <Swipe
          ref={instance => (this.heroSwipe = instance)}
          onActiveIndexChange={onActiveChange}
          loop
        >
          {slides}
        </Swipe>
        <SliderArrows
          className={styles.arrow}
          prevHandler={this.prevHandler}
          nextHandler={this.nextHandler}
          arrowsShift={44}
          type='tertiary'
        />
        <Bullets
          totalCount={totalCount}
          activeIndex={activeIndex}
          onChange={this.goToHandler}
        />
      </div>
    );
  }
}

export default Slider;
