import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';
import bindPropsHOC from 'core/components/bindProps';
import themeSwitcher from 'core/components/themeSwitcher';

import Link from 'core/components/Link';
import Texts from 'core/components/Texts';
import Scroller from 'core/components/Scroller';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import ArrowTopRight from 'site/icons/ArrowTopRight';

import styles from './index.styl';

import { PILES_DATA } from '../constants';


const LinkPrimary = bindPropsHOC({ type: 'primary' })(Link);
const LinkPrimaryDark = themeSwitcher('dark')(LinkPrimary);

function PVPMenuDesktop({ theme, menus }) {
  const {
    colors: {
      blue500,
      black,
      white,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.pile}
          &:hover
            &:before
              background-color ${blue500}

            .${styles.button}
              color ${white}

          &:not(:hover)
            .${styles.button}
              background-color ${white}

        .${styles.button}
          color ${black}

        .${styles.buttonText}
          font ${theme.texts.button.font}
      `}</style>
    </scope>
  );

  return (
    <div className={styles.pvpMenuDesktop}>
      {PILES_DATA.map(renderData => {
        const {
          buttonText,
          slug,
        } = renderData;

        const menu = menus[slug]?.attributes.menu_items;

        return (
          <div
            key={slug}
            className={scope.wrapClassNames(styles.pile)}
          >
            <LinkPrimary
              to={'/' + slug}
              className={scope.wrapClassNames(styles.button)}
              title={buttonText}
            >
              <span className={scope.wrapClassNames(styles.buttonText)}>
                {buttonText}
              </span>
            </LinkPrimary>
            {menu && (
              <div className={styles.menu}>
                <Scroller listClassName={styles.scrollerList}>
                  <Texts type='buttonCTA' className={styles.menuExpanded}>
                    {menu.concat({ link: '/' + slug, content: 'Читать всё' }).map((item, index, arr) => {
                      const isLast = index === arr.length - 1;
                      return (
                        <LinkPrimaryDark
                          key={item.link}
                          to={item.link}
                          className={cx(styles.menuLink, isLast && styles._lastMenuLink)}
                        >
                          <span className={styles.menuLinkText}>{item.content}</span>
                          {isLast && <div className={scope.wrapClassNames(styles.arrowTopRight)}><ArrowTopRight width='18rem' height='18rem' /></div>}
                        </LinkPrimaryDark>
                      );
                    })}
                  </Texts>
                </Scroller>
              </div>
            )}
          </div>
        );
      })}
      <scope.styles />
    </div>
  );
}

PVPMenuDesktop.propTypes = {
  menus: PropTypes.object,
  theme: themePropTypes(`
    {
      colors: {
        green400,
        black,
        white,
      },
    }
  `),
};

const PvpMenu = themeSwitcher('light')(withTheme(PVPMenuDesktop));

export { PVPMenuDesktop as StorybookComponent };
export default PvpMenu;
