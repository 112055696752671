import cx from 'classnames';

import styles from './index.styl';


export default function CardsFeedSkeleton() {
  return (
    <div className={styles.feed}>
      {new Array(4).fill(null).map((v, i) => (
        <div key={i} className={cx(styles.card, 'lb-skeleton')} />
      ))}
    </div>
  );
}
