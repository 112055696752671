import cx from 'classnames';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint } from 'core/components/breakpoint';

import Link from 'core/components/Link';
import Texts from 'core/components/Texts';

import styles from './index.styl';

const relationships = resolveRelationships(['image'], null, {
  image: {
    caption: null,
    credits: null,
    credits_url: null,
  },
});

function Copyright({ content, className, isMobile }) {
  const {
    image: {
      caption,
      credits,
      credits_url: creditsUrl,
    },
  } = relationships(content);

  if (!credits) return null;

  const captionCredits = caption ? `${caption}/${credits}` : credits;

  return (
    <Texts type={isMobile ? 'bodySmall' : 'button'} className={cx(styles.copyright, className)}>
      {creditsUrl
        ? (
          <Link
            className={styles._overflow}
            type='primary'
            to={creditsUrl}
          >
            &copy;&nbsp;
            {captionCredits}
          </Link>
        )
        : <span className={styles._overflow}>&copy;&nbsp;{captionCredits}</span>
      }
    </Texts>
  );
}

Copyright.propTypes = {
  content: PropTypes.object,
  className: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Copyright);
