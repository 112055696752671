import React from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';

import Texts from 'core/components/Texts';
import Link from 'core/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';
import bindPropsHOC from 'core/components/bindProps';

import themePropTypes from 'core/utils/prop-types/theme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import styles from './index.styl';


function SectionHeader({ link, count, theme, titleType, children }) {
  if (!children) return null;

  const { blue500, blue900 } = theme.colors;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.count}
          color ${blue500}

        .${styles.link}
          &:hover
            .${styles.count}
              color ${blue500}

          &:active
            .${styles.count}
              color ${blue900}
      `}</style>
    </scope>
  );

  let LinkOrEmpty = EmptyWrapper;
  if (link) {
    LinkOrEmpty = bindPropsHOC({
      to: link,
      type: 'section',
      className: scope.wrapClassNames(styles.link),
    })(Link);
  }

  return (
    <Texts
      type={titleType}
      className={styles.texts}
      tag='h2'
    >
      <LinkOrEmpty>
        {children} {!!count && <span className={scope.wrapClassNames(styles.count)}>({count})</span>}
      </LinkOrEmpty>
      <scope.styles />
    </Texts>
  );
}

SectionHeader.defaultProps = {
  titleType: 'title2',
};

SectionHeader.propTypes = {
  /**
   * Число в скобочках после заголовка
   */
  count: PropTypes.number,

  /**
   * Превращает заголовок в ссылку
   */
  link: PropTypes.string,

  /** @ignore */
  theme: themePropTypes(`
    {
      colors: {
        blue500,
        blue900,
      },
    }
  `),
  titleType: PropTypes.oneOf(['title2', 'accent']),
};

SectionHeader.displayName = 'SectionHeader';

export { SectionHeader as StorybookComponent };

export default withTheme(SectionHeader);
