import styles from './index.styl';

const BlueRectangleWrapper = ({ children }) => (
  <div className={styles.blueWrapper}>
    <div className={styles.inner}>
      {children}
    </div>
  </div>
);

export default BlueRectangleWrapper;
