import modelPropTypes, { opinionAttributes } from 'core/utils/prop-types/model';

import themeSwitcher from 'core/components/themeSwitcher';

import Texts from 'core/components/Texts';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import RadialBackground from 'site/components/RadialBackground';
import GradientBackground from 'site/components/GradientBackground';

import styles from './index.styl';
import PropTypes from 'prop-types';


function Opinion({ block, parentProps }) {
  const {
    attributes: {
      author,
      body,
    },
  } = block;

  const { isDesktop } = parentProps;
  const name = author?.attributes?.name;
  const jobTitle = author?.attributes?.job_title;
  const authorString = [name, jobTitle].filter(Boolean).join(', ');

  const Content = (
    <div className={styles.content}>
      <Texts type={isDesktop ? 'accent' : 'title4'}>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </Texts>
      <Texts type={isDesktop ? 'title4' : 'bodySmall'} className={styles.author}>
        {authorString}
      </Texts>
    </div>
  );

  if (isDesktop) {
    return (
      <RadialBackground colorName='blue'>
        {Content}
      </RadialBackground>
    );
  }

  return (
    <GradientBackground className={styles.gradient}>
      <div className={styles.opinion}>
        {Content}
      </div>
    </GradientBackground>
  );
}

Opinion.propTypes = {
  parentProps: PropTypes.object,
  block: modelPropTypes(opinionAttributes).isRequired,
};

export default themeSwitcher('dark')(Opinion);
