/**
 * Не получилось сделать так, чтобы в качестве параметров отдавался только номер
 * страницы, поэтому парсим pageSlug так
 */
export function getPageNumber(pageSlug) {
  return pageSlug ? Number(pageSlug.replace('page-', '')) : 1;
}

export function getCanonical(pathname) {
  return pathname.replace(/(\/page-\d+)$/i, '');
}

export function getTotalPages(filteredCount, startOffset, limit) {
  return Math.ceil((filteredCount - startOffset) / limit) + 1;
}

export function getTitleTemplate(defaultTemplate, page) {
  return page > 1
    ? `${defaultTemplate} - страница ${page}`
    : defaultTemplate;
}

export function pageLinkConstructor(canonical, pageNum) {
  if (pageNum < 2) return canonical;
  return `${canonical}/page-${pageNum}`;
}
