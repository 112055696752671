import PropTypes from 'prop-types';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';
import { prepareWidgetsBlock } from 'core/utils/content';

import withTheme from 'core/components/theme';

import { Indent } from 'core/components/Wrappers';
import TopicContent from 'core/components/TopicContent';

import * as siteWidgets from 'site/widgets'; // eslint-disable-line import/no-namespace

import { PVP_SLUG } from 'site/constants';


const blocks = prepareWidgetsBlock(siteWidgets);

/**
 * В ЛБ виджеты 'markdown' склеиваются, и чтобы учитывать это,
 * нужно провести склейку здесь и просчитать индексы с учётом этого.
 **/

const getJoinedWidgets = (content) => content
  .map(topic => topic.type)
  .reduce((result, type) => {
    const lastEl = result[result.length - 1];

    if (lastEl && lastEl === 'markdown' && type === 'markdown') {
      return result;
    }

    return [...result, type];
  }, []);

const relationships = resolveRelationships(['content'], {}, {
  content: {
    widgets: [],
  },
});

function Content({ content, showTopicHeader, theme }) {
  const {
    attributes: {
      topic_type: topicType,
    },
  } = content;

  const {
    content: {
      widgets: contentWidgets,
    },
  } = relationships(content);

  const joinedWidgets = getJoinedWidgets(contentWidgets);
  const lastMarkdownPosition = joinedWidgets.lastIndexOf('markdown');

  const { topicContent } = theme.controls;

  return (
    <>
      <TopicContent
        blocks={blocks}
        content={contentWidgets}
        disableDefaultNavigation
        showTopicHeader={showTopicHeader}
        isPVP={topicType === PVP_SLUG}
        lastWidgetPosition={joinedWidgets.length - 1}
        lastMarkdownPosition={lastMarkdownPosition}
      />
      <Indent bottom={Math.abs(topicContent.widget.bottom)} />
    </>
  );
}

Content.propTypes = {
  content: PropTypes.object,
  showTopicHeader: PropTypes.bool,
  theme: themePropTypes(`
    {
      controls: {
        topicContent: {
          widget: {
            bottom,
          }
        },
      },
    }
  `),
};

export default withTheme(Content);
